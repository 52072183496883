<template>
    <div id="news" class="flex-colum flex-align-center">
        <div style="margin-top: 7.06rem" class="news-title flex-row flex-center">
            <div class="news-icon bgImg-contain"></div>
            <div class="news-title-text">
                <span>新闻动态</span>
            </div>
        </div>
        <div class="news-container flex-row flex-align-start flex-jusity-space-between">
            <div class="news-left-container flex-colum flex-align-center">
                <div class="news-hot-title default-size">
                    <span>热点新闻</span>
                </div>
                <div v-for="(item, index) in newsData" :key="'new_'+index" @click="toPush(item.id)" class="cur-pointer news-row flex-row flex-align-center default-size tophot" :title="item.title" >
                    <i></i><span class="news-date">{{item.timeDate}} </span>
                    <span class="news-title">
                        {{item.title}}
                    </span>
                </div>
            </div>
            <div class="news-right-container position-rel">
                <swiper>
                    <swiper-slide v-for="(item, index) in newsData" :key="'news_'+index">
                        <div :title="item.title"  @click="toPush(item.id)" :style="`background-image: url(${item.image})`" class="cur-pointer new-nav-bg-pic bgImg-contain default-size flex-colum flex-align-center position-rel">
                            <div class="nav-bottom flex-colum position-abs">
                                <div class="bottom-title">
                                    <span>{{item.title}}</span>
                                </div>
                                <div class="bottom-brief">
                                    <span>{{item.des}}</span>
                                </div>
                                <div class="bottom-date-more flex-row flex-jusity-space-between position-abs">
                                    <span>{{item.timeDate}}</span>
                                    <span class="cur-pointer">查看全文</span>
                                </div>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <div @click="toUrl('http://www.minhandedu.com/xwgh/')" class="more-news flex-row flex-center cur-pointer btn-action">
            <span>更多新闻</span><i class="more-icon bgImg-contain"></i>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      newsData: []
    }
  },
  mounted () {
    this.axios({
      url: '/index.php/?c=index&a=news',
      method: 'get'
    }).then(res => {
      this.newsData = res.data.list.map((item, index) => {
        return {
          id: item.id,
          timeDate: this.dateFormat('YYYY-mm-dd', new Date(parseInt(item.time) * 1000)),
          title: item.title,
          des: item.description,
          image: '//www.minhandedu.com' + item.thumb
        }
      })
    })
  },
  methods: {
    toPush (index) {
      window.location = 'http://www.minhandedu.com/xwgh/' + index + '.html'
    },
    toUrl (url) {
      window.location = url
    },
    dateFormat (fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString() // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
        };
      };
      return fmt
    }
  }
}
</script>

<style lang="scss" scoped>
    #news{
        .news-title{
            .news-icon{
                width: 4.38rem;
                height: 4.69rem;
                background-image: url("../../assets/img/home/Home/News/新闻动态.png");
            }
            .news-title-text{
                margin-left: 1.19rem;
                user-select: none;
                span{
                    font-size: 2.5rem;
                    font-weight: 600;
                    color: #8071F6;
                    line-height: 3.5rem;
                }
            }
        }
        .news-container{
            width: 77.5rem;
            height: 30.31rem;
            .news-left-container{
                width: 42.75rem;
                height: 24.63rem;
                .news-hot-title{
                    border-bottom: 0.2rem dashed #888888;
                    span{
                        font-size: 1.63rem;
                        font-weight: 600;
                        color: #8071F6;
                        line-height: 2.31rem;
                        display: inline-block;
                        margin-bottom: 0.94rem;
                    }
                }
                .tophot{
                    border-bottom: 0.2rem dashed #888888 !important;
                }
                .news-row{
                    height: 3.75rem;
                    justify-content: flex-start;
                    border-bottom: 0.05rem dashed #DDDCDC;
                    i{
                        display: inline-block;
                        background-size: contain;
                        background-repeat: no-repeat;
                        width: 0.44rem;
                        height: 0.69rem;
                        background-image: url("../../assets/img/home/Home/News/向左.png");
                    }
                    .news-date{
                        width: 6rem;
                        white-space: nowrap;
                        display: inline-block;
                        margin-left: 0.38rem;
                        font-size: 0.93rem;
                        font-weight: bold;
                        color: #4A4A4A;
                        line-height: 1.31rem;
                    }
                    .news-title{
                        width: 32rem;
                        display: inline-block;
                        margin-left: 2.81rem;
                        font-size: 1rem;
                        font-weight: 400;
                        color: #666666;
                        line-height: 1.38rem;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
            .news-right-container{
                width: 33.69rem;
                height: 30.31rem;
                border-radius: 1rem;
                .new-nav-bg-pic{
                    width: 33.69rem;
                    height: 25.8rem;
                    background-position: center;
                    border-radius: 1rem;
                    .nav-bottom{
                        width: 100%;
                        height: 10rem;
                        background: rgba(0,0,0,.6775);
                        border-radius: 0rem 0rem 0.5rem 0.5rem;
                        bottom: 0;
                        .bottom-title{
                            margin-top: 0.5rem;
                            margin-left: 0.8rem;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            span{
                                font-size: 1.38rem;
                                font-weight: 600;
                                color: #FFFFFF;
                                line-height: 1.88rem;
                            }
                        }
                        .bottom-brief{
                            margin-top: 0.63rem;
                            margin-left: 0.8rem;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            span{
                                font-size: 1rem;
                                font-weight: 400;
                                color: #FFFFFF;
                                line-height: 1.38rem;
                            }
                        }
                        .bottom-date-more{
                            width: 31rem;
                            margin-left: 1rem;
                            font-size: 0.88rem;
                            font-weight: bold;
                            color: #FFFFFF;
                            line-height: 1rem;
                            bottom: 0.6rem;
                        }
                    }
                }
            }
        }
        .more-news{
            width: 10.31rem;
            height: 2.88rem;
            background: #8071F6;
            // box-shadow: 0rem 0.38rem 0.56rem 0rem rgba(106, 50, 139, 0.36);
            border-radius: 2.78rem;
            cursor: pointer;
            span{
                font-size: 1.25rem;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 1.75rem;
                user-select: none;
            }
            .more-icon{
                margin-left: 0.37rem;
                display: inline-block;
                width: 0.88rem;
                height: 0.88rem;
                background-image: url("../../assets/img/home/Home/News/更多.png");
            }
        }
    }
</style>
