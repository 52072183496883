<template>
    <div id="banner" class="default-size position-rel">
        <div id="prev-btn" :class="`arrow-left position-abs flex-colum flex-jusity-center cur-pointer`">
            <div class="left-arrow bgImg-cover"></div>
        </div>
        <div id="next-btn" :class="`arrow-right position-abs flex-colum flex-jusity-center cur-pointer`">
            <div class="right-arrow bgImg-cover"></div>
        </div>
        <swiper ref="bannerSwiper" :options="bannerSwipeOption">
            <swiper-slide>
                <div :key="'../../assets/img/home/Home/Banner/banner1.png'" :style="{backgroundImage: 'url('+require('../../assets/img/home/Home/Banner/banner1.png')+')'}" class="banner-img bgImg-cover default-size flex-colum flex-center">
                    <!-- <div class="banner-title bgImg-contain"></div> -->
                    <!-- <div class="banner-title bgImg-contain">曼汉教育</div>
                    <div class="banner-title-english"><span>M  I  N  H  A  N  D</span><span style="margin-left:2rem">E D U C A T I O N</span></div>
                    <div class="banner-subtitle">
                        <span>让知识流动起来</span>
                    </div> -->
                </div>
            </swiper-slide>
            <swiper-slide>
                <div :key="'../../assets/img/home/Home/Banner/banner2.png'" v-lazy:background-image="require('../../assets/img/home/Home/Banner/banner2.png')" class="banner-img bgImg-cover default-size flex-colum flex-center">
                    <div class="banner-title2">
                        <!-- <span class="spans">曼汉教育之</span><span class="title">全球博士孵化领航品牌</span> -->
                        <span class="title">曼博士™博士教育</span>

                    </div>
                    <div class="banner-subtitle2">
                        <span>——曼汉教育旗下全球一站式博士教育平台——</span>
                    </div>
                    <div class="text">
                        <span>博学求真  思而不凡</span>
                    </div>
                    <div class="btns flex-row flex-jusity-space-between flex-align-center">
                        <div @click="toUrl(1)" class="detail flex-center flex-colum cur-pointer btn-action">
                            <span>了解详情</span>
                        </div>
                        <div @click="openZoos" class="custom flex-center flex-colum cur-pointer btn-action">
                            <span>免费定制申博规划</span>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div :key="'../../assets/img/home/Home/Banner/banner3.png'" v-lazy:background-image="require('../../assets/img/home/Home/Banner/banner3.png')" class="banner-img bgImg-cover default-size">
                    <div class="banner-content flex-colum flex-align-start">
                        <div class="banner-title3">
                            <span>曼研™科研教育</span>
                        </div>
                        <div class="text3">
                            <span>——曼汉教育旗下在线科研教育服务平台</span>
                        </div>
                        <div class="banner-subtitle3">
                            <span>
                                科研人生，背景提升，成就非凡的你！
                            </span>
                        </div>
                        <div class="btns flex-row flex-jusity-space-between flex-align-center">
                            <div @click="toUrl(2)" class="detail flex-center flex-colum cur-pointer btn-action">
                                <span>了解详情</span>
                            </div>
                            <div @click="openZoos" class="custom flex-center flex-colum cur-pointer btn-action">
                                <span>科研提升直通车</span>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div :key="'../../assets/img/home/Home/Banner/banner4.png'" v-lazy:background-image="require('../../assets/img/home/Home/Banner/banner4.png')" class="banner-img bgImg-cover default-size flex-colum flex-center">
                    <div class="banner-title4">
                        <span class="span">曼知<span class="spanR" style="font-size: 2.38rem;line-height: 2.13rem;">®</span><span class="spanXyjy">学业教育</span></span>
                        <!-- <span class="title4">高端学业辅导品牌</span> -->
                    </div>
                    <div class="text-4">
                        <span>——曼汉教育旗下一站式国际学业教育服务平台——</span>
                    </div>
                    <div class="banner-subtitle4">
                        <span>
                            Mind Tutoring . 知享未来
                        </span>
                    </div>
                    <div class="btns4 flex-row flex-jusity-space-between flex-align-center">
                        <div @click="toUrl(3)" class="detail flex-center flex-colum cur-pointer btn-action">
                            <span>了解详情</span>
                        </div>
                        <div @click="openZoos" class="custom flex-center flex-colum cur-pointer btn-action">
                            <span>1V1 定制</span>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="swiper-pagination position-abs flex-row flex-jusity-space-between">
            <div @click="bannerSwiperSlideTo(0)" :class="`swiper-pagination-bullet ${bannerPageData.currentIndex == 0 ? 'swiper-pagination-bullet-active' : ''}`"></div>
            <div @click="bannerSwiperSlideTo(1)" :class="`swiper-pagination-bullet ${bannerPageData.currentIndex == 1 ? 'swiper-pagination-bullet-active' : ''}`"></div>
            <div @click="bannerSwiperSlideTo(2)" :class="`swiper-pagination-bullet ${bannerPageData.currentIndex == 2 ? 'swiper-pagination-bullet-active' : ''}`"></div>
            <div @click="bannerSwiperSlideTo(3)" :class="`swiper-pagination-bullet ${bannerPageData.currentIndex == 3 ? 'swiper-pagination-bullet-active' : ''}`"></div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Vue from 'vue'
export default {
  data () {
    return {
      bannerPageData: {
        currentIndex: 0,
        prevFlag: false,
        nextFlag: true

      },
      bannerSwiperSlides: [],
      bannerSwipeOption: {
        autoplay: true,
        initialSlide: this.$nextTick(() => { return this.bannerPageData.currentIndex }),
        // preventInteractionOnTransition : true,
        navigation: {
          nextEl: '#next-btn',
          prevEl: '#prev-btn'
        },
        on: {
          init: (swiper) => {
            this.$nextTick(() => {
              this.bannerSwiperSlides = swiper.slides
            })
          },
          slideChangeTransitionStart: (swiper) => {
            this.$nextTick(() => {
              this.$set(this.bannerPageData, 'currentIndex', swiper.activeIndex)
            })
          },
          slideChangeTransitionEnd: (swiper) => {
            this.$nextTick(() => {
              // console.log(swiper.activeIndex,this.bannerSwiperSlides.length)
              if (swiper.activeIndex == 0) {
                this.$set(this.bannerPageData, 'prevFlag', false)
                return
              }
              if (swiper.activeIndex == (this.bannerSwiperSlides.length - 1)) {
                this.$set(this.bannerPageData, 'nextFlag', false)
                return
              }
              this.$set(this.bannerPageData, 'prevFlag', true)
              this.$set(this.bannerPageData, 'nextFlag', true)
            })
          }
        }
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    bannerSwiperSlideTo (index) {
      // console.log(index);
      this.$nextTick(() => {
        this.$refs.bannerSwiper.$swiper.slideTo(index)
      })
    },
    openZoos () {
      openJesongChatByGroup(34320, 48064)
      return false
    },
    toUrl (index) {
      switch (index) {
        case 1:
          window.location = 'http://www.minhandedu.com/bssq/#/'
          break
        case 2:
          window.location = 'http://www.minhandedu.com/kyjy/'
          break
        case 3:
          window.location = 'http://www.minhandedu.com/xyfd/#/'
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    #banner{
        height: 45.63rem;
        .arrow-left{
            width: 4.7rem;
            height: 100%;
            left: 0;
            top: 0;
            background-color: rgba(0,0,0,0);
            z-index: 2;
            transition: all .3s;
            .left-arrow{
                margin-left: 1.15rem;
                width: 1.31rem;
                height: 2.31rem;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAABKCAYAAADNN8YBAAADrElEQVRoQ9Xb6a+ORxjH8e9lr6V4V/E3kEgFLQ0OaomgEZEq0jT4Q7z1D1CxN2Krfal4QSwhKFFbqBa1r7W//cmcDJnD4bmv+zznPvPM65nM51z3mbnvmet6jEyapH7AYmACMBA4D6w0sxWBaDk4JQ0B9gJfteLZYmazOhwq6WvgAND/M0Fb1KFQSUMjMjz2z7UTHQaVNAzYD9RChj/gZYdAJQ2PyL4F18jFyqGSvgH+AL4siAzdllQKlfRtRPZxIP8FBlcGlTQqbkEe5G1grJldqwQq6buI7O2I5K2I/KeSDV/SaGAP0MuB/C8iw2Nvbu0aUUljIrKnA3kTGGNmN9Ix7QaV1ATsAjzIgAvIgG3R2gUqaTywE/jCEcnrERke+0et7lBJ4etnhxMZFkxY3WEBtdrqCpU0EdgO9HBE8lpEhq3ok61uUEmTgW1Adwfy74i8U2tMXaCSpgBbncirEXm3FrIu25OkqcDvQLciE8Y+VyLyXtExbYqopGnAZifyMtBkZveLItsUUUkzgE1AV8eElyLygWNMc9dSEZX0A7DRibwYkQ+9yFJQSTOBDUAXx4ThRDnOzB45xpR/M0maBax3Iv+KyMdlka6ISpoN/OZEnovIJ21BFoZK+hFYB3R2THgWGG9mTx1jyr+ZJP0ErHEiz4Qbj3oha0ZU0jxgNdDJEZXTwPdm9r9jTM2un9yeJM0HVjmRpyLyWc2ZnR1ahUr6GQiXU55InozI505Doe4fQSX9Aix3Ik8AE83sRaFZS3RqAZW0APjV+cY6DkxqT2SLxSRpEbDUiTwGTDazlyWC5BrSHFFJC4FlTuQRYIqZvXLNWLKzSRoEhC3F8z15OCJfl5zXPSxAwxlnumPkIWCqmVWGbP4flRSOAgMKQg9G5JuC/evWraGgDfPoG2MxtWF7Ohr30Gq2p3f/7Q2x4SfY/F+hCTb/j5IEm/9nXoLN/8M5wc6N5yXPB/Sf8bxUzVEkwc4B1mZ9uEuw+R+XE2z+FxAJNv8rnQRb5pLsQrzJq+aSLMHmf+2YYMOpINw253uRm2DzvxpPsPknGxJs/umbBDspZu3yTYgl2PxTjAm2TNI2FBCE9Hc1SdsEOy7m6vNNgyfY/AsLEmwo1djtrCcJ1Q8hdx8KDd63Upk7zz1NQxS/JJHNv5wowY4E9gH5Fmgl2PxL3hLsiFjpmG8RYYLNvywzweZf6Jpg8y8dTrD5F2Mn2FDeHioiW8sl5FHenmA//MFAOLmuePeDgbdfI6tEraqdOQAAAABJRU5ErkJggg==);
            }
        }
        .arrow-left:hover{
            background: linear-gradient(to right, rgba(0,0,0,.26),rgba(0,0,0,0)) ;
        }
        .arrow-right{
            width: 4.7rem;
            height: 100%;
            right: 0;
            top: 0;
            background-color: rgba(0,0,0,0);
            z-index: 2;
            transition: all .3s;
            .right-arrow{
                transform: rotate(180deg);
                margin-left: 2.05rem;
                width: 1.31rem;
                height: 2.31rem;
                background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAABKCAYAAADNN8YBAAADrElEQVRoQ9Xb6a+ORxjH8e9lr6V4V/E3kEgFLQ0OaomgEZEq0jT4Q7z1D1CxN2Krfal4QSwhKFFbqBa1r7W//cmcDJnD4bmv+zznPvPM65nM51z3mbnvmet6jEyapH7AYmACMBA4D6w0sxWBaDk4JQ0B9gJfteLZYmazOhwq6WvgAND/M0Fb1KFQSUMjMjz2z7UTHQaVNAzYD9RChj/gZYdAJQ2PyL4F18jFyqGSvgH+AL4siAzdllQKlfRtRPZxIP8FBlcGlTQqbkEe5G1grJldqwQq6buI7O2I5K2I/KeSDV/SaGAP0MuB/C8iw2Nvbu0aUUljIrKnA3kTGGNmN9Ix7QaV1ATsAjzIgAvIgG3R2gUqaTywE/jCEcnrERke+0et7lBJ4etnhxMZFkxY3WEBtdrqCpU0EdgO9HBE8lpEhq3ok61uUEmTgW1Adwfy74i8U2tMXaCSpgBbncirEXm3FrIu25OkqcDvQLciE8Y+VyLyXtExbYqopGnAZifyMtBkZveLItsUUUkzgE1AV8eElyLygWNMc9dSEZX0A7DRibwYkQ+9yFJQSTOBDUAXx4ThRDnOzB45xpR/M0maBax3Iv+KyMdlka6ISpoN/OZEnovIJ21BFoZK+hFYB3R2THgWGG9mTx1jyr+ZJP0ErHEiz4Qbj3oha0ZU0jxgNdDJEZXTwPdm9r9jTM2un9yeJM0HVjmRpyLyWc2ZnR1ahUr6GQiXU55InozI505Doe4fQSX9Aix3Ik8AE83sRaFZS3RqAZW0APjV+cY6DkxqT2SLxSRpEbDUiTwGTDazlyWC5BrSHFFJC4FlTuQRYIqZvXLNWLKzSRoEhC3F8z15OCJfl5zXPSxAwxlnumPkIWCqmVWGbP4flRSOAgMKQg9G5JuC/evWraGgDfPoG2MxtWF7Ohr30Gq2p3f/7Q2x4SfY/F+hCTb/j5IEm/9nXoLN/8M5wc6N5yXPB/Sf8bxUzVEkwc4B1mZ9uEuw+R+XE2z+FxAJNv8rnQRb5pLsQrzJq+aSLMHmf+2YYMOpINw253uRm2DzvxpPsPknGxJs/umbBDspZu3yTYgl2PxTjAm2TNI2FBCE9Hc1SdsEOy7m6vNNgyfY/AsLEmwo1djtrCcJ1Q8hdx8KDd63Upk7zz1NQxS/JJHNv5wowY4E9gH5Fmgl2PxL3hLsiFjpmG8RYYLNvywzweZf6Jpg8y8dTrD5F2Mn2FDeHioiW8sl5FHenmA//MFAOLmuePeDgbdfI6tEraqdOQAAAABJRU5ErkJggg==);
            }
        }
        .arrow-right:hover{
            background: linear-gradient(to left, rgba(0,0,0,.26),rgba(0,0,0,0)) ;
        }
        .banner-img{
            width: 100%;
            height: 45.63rem;
            background-color: grey;
            background-position: center;
            user-select: none;
            border-top: 1px solid black;
            .banner-title{
                width: 33.81rem;
                // height: 10.38rem;
                font-size: 6.5rem;
                text-align: center;
                color: #A6B6F8;
                // background-image: url("../../assets/img/home/Home/Banner/曼汉教育@2x.png");
            }
            .banner-title-english{
                font-size: 1.5rem;
                text-align: center;
                color: rgb(176,168,164);

            }
            .banner-subtitle{
                margin-top: 2.44rem;
                span{
                    font-size: 3.69rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 5.19rem;
                    letter-spacing: 7px;
                    user-select: none;
                }
            }
            .banner-title2{
                .spans{
                    font-size: 3.38rem;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 4.69rem;
                    // -webkit-background-clip: text;
                    // -webkit-text-fill-color: transparent;
                }
                .title{
                    margin-left: 0.5rem;
                    font-size: 4.63rem;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 5.69rem;
                    background: linear-gradient(180deg, #FFFFFF 0%, #A6B6F8 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .banner-subtitle2{
                span{
                    font-size: 2rem;
                    font-weight: 400;
                    font-family: PingFangSC-Regular, PingFang SC;
                    color: #FFFFFF;
                    line-height: 2.81rem;
                }
            }
            .text{
                margin-top: 2.13rem;
                span{
                    font-size: 2.13rem;
                    // font-weight: bold;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 3rem;
                }
            }
            .btns{
                width: 29.56rem;
                height: auto;
                margin-top: 3.75rem;
                .detail{
                    width: 12rem;
                    height: 4rem;
                    box-shadow: 0rem 0.13rem 1.88rem 0rem rgba(0, 0, 0, 0.5);
                    border-radius: 0.25rem;
                    border: 0.06rem solid #FFFFFF;
                    span{
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 2.06rem;
                    }
                }
                .custom{
                    width: 14.69rem;
                    height: 4rem;
                    background: #8071F6;
                    border-radius: 0.25rem;
                    span{
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 2.06rem;
                    }
                }
            }
            .banner-content{
                width: 48.75rem;
                height: 24.25rem;
                margin-top: 12.63rem;
                margin-left: 23.81rem;
                .banner-title3{
                    font-size: 5rem;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 5.25rem;
                }
                .text3{
                    margin-top: 0.83rem;
                    span{
                        font-size: 1.88rem;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 2.63rem;
                    }
                }
                .banner-subtitle3{
                    margin-top: 3.13rem;
                    span{
                        font-size: 2.25rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 3.13rem;
                    }
                }
            }
            .banner-title4{
                position: relative;
                .span{
                    font-size: 4.38rem;
                    color: #FFFFFF;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    line-height: 6.13rem;
                }
                .spanR{
                    position: absolute;
                    top: 1rem;
                    // display:block;
                }
                .spanXyjy{
                    margin-left:2rem
                }
                .title4{
                    font-size: 4.38rem;
                    font-weight: 400;
                    color: #8071F6;
                    line-height: 6.13rem;
                }

            }
            .text-4{
                span{
                    font-size: 1.88rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 2.63rem;
                }
            }
            .banner-subtitle4{
                margin-top: 2.81rem;
                span{
                    // font-size: 2.5rem;
                    font-size: 1.88rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 3.5rem;
                }
            }
            .btns4{
                width: 26.88rem;
                height: auto;
                margin-top: 5.13rem;
                .detail{
                    width: 12rem;
                    height: 4rem;
                    box-shadow: 0rem 0.13rem 1.88rem 0rem rgba(0, 0, 0, 0.5);
                    border-radius: 0.25rem;
                    border: 0.06rem solid #FFFFFF;
                    span{
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 2.06rem;
                    }
                }
                .custom{
                    width: 12rem;
                    height: 4rem;
                    background:  #8071F6;
                    border-radius: 0.25rem;
                    span{
                        font-size: 1.5rem;
                        font-weight: 600;
                        color: #FFFFFF;
                        line-height: 2.06rem;
                    }
                }
            }
        }
        .swiper-pagination{
            width: 13.94rem;
            min-height: 0.25rem;
            bottom: 3.5rem;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 3;
            .swiper-pagination-bullet{
                display: inline-block;
                width: 1.69rem;
                height: 0.25rem;
                background: rgba(255,255,255,.2376);
                border-radius: 0.13rem;
                transition: all .3s;
                cursor: pointer;
            }
            .swiper-pagination-bullet-active{
                display: inline-block;
                width: 1.69rem;
                height: 0.25rem;
                background: rgb(255,255,255) !important;
                border-radius: 0.13rem;
                transition: all .3s;
            }
        }
    }
</style>
