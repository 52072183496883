<template>
    <div class="container position-rel">
        <div class="content flex-row flex-jusity-space-between flex-align-center">
            <div class="content-left flex-colum flex-align-start">
                <div class="advantage">
                    <span>
                        {{advantage}}
                    </span>
                </div>
                <div class="biref">
                    <span>
                        {{biref}}
                    </span>
                </div>
                <div class="text">
                    <span>
                        {{text}}
                    </span>
                </div>
            </div>
            <div class="content-right bgImg-cover" :style="`background-image: url(${imgPath})`">

            </div>
        </div>
        <div class="number position-abs">
            <span>
                {{number}}
            </span>
        </div>
    </div>
</template>

<script>
export default {
  props: ['number', 'advantage', 'biref', 'text', 'imgPath']
}
</script>

<style lang="scss" scoped>
    .container{
        user-select: none;
        width: 37rem;
        height: 15.19rem;
        background-color: #FFFFFF;
        border-radius: 0.5rem;
        border-top: 1px solid #F3EEFC;
        .content{
            width: 33.5rem;
            height: 9.63rem;
            margin-top: 2.81rem;
            margin-left: 1.75rem;
            .content-left{
                width: 15.75rem;
                height: 100%;
                .advantage{
                    background-color: #FFFFFF;
                    margin-top: 0.94rem;
                    span{
                        font-size: 1.38rem;
                        font-weight: 500;
                        color: #4B4C5E;
                        line-height: 1.88rem;
                    }
                }
                .biref{
                    margin-top: 1.13rem;
                    span{
                        font-size: 0.94rem;
                        font-weight: bold;
                        color: #8071F6;
                        line-height: 0.88rem;
                    }
                }
                .text{
                    margin-top: 0.55rem;
                    span{
                        font-size: 0.88rem;
                        font-weight: 400;
                        color: #6A6B7B;
                        line-height: 1.38rem;
                    }
                }
            }
            .content-right{
                width: 15.75rem;
                height: 9.63rem;
            }
        }
        .number{
            opacity: 0.24;
            top: 1.75rem;
            left: 1.75rem;
            span{
                font-size: 3.75rem;
                font-weight: bold;
                color: #8071F6;
                line-height: 4.38rem;
            }
        }
    }
</style>
