<template>
    <div id="header" class="flex-row flex-center default-size" :style="`${headerViewFlag ? 'background: rgba(0,0,0,0.75)':''}`" >
        <!-- -->
        <my-image width="20.63rem" :drag="false" height="2.94rem" :src="require('@/assets/img/home/header/logo.png')" />
        <div class="header-nav flex-colum flex-center">
            <span class="checked">首页</span>
        </div>
        <div class="header-nav flex-colum flex-center select">
            <a :href="`http://www.minhandedu.com/bssq/#/?pc=`+this.$route.query.pc"><span>博士留学</span></a>
             <div class="nav_list_child">
                <ul>
                    <div class="one">
                        <li><a :href="`https://www.minhandedu.com/mbs/uk.html?pc=`+this.$route.query.pc"  target="_blank">英国</a></li>
                        <li><a :href="`https://www.minhandedu.com/mbs/usa.html?pc=`+this.$route.query.pc"  target="_blank">美国</a></li>
                        <li><a :href="`https://www.minhandedu.com/mbs/aus.html?pc=`+this.$route.query.pc"  target="_blank">澳洲</a></li>
                        <li><a :href="`https://www.minhandedu.com/mbs/sg.html?pc=`+this.$route.query.pc"  target="_blank">新加坡</a></li>
                        <li><a :href="`https://www.minhandedu.com/mbs/hk.html?pc=`+this.$route.query.pc"  target="_blank">香港澳门</a></li>
                    </div>
                    <div class="two">
                        <li><a :href="`https://www.minhandedu.com/mbs/can.html?pc=`+this.$route.query.pc">加拿大</a></li>
                        <li><a :href="`https://www.minhandedu.com/mbs/europe.html?pc=`+this.$route.query.pc">欧洲</a></li>
                    </div>
                </ul>
            </div>
        </div>
        <div class="header-nav flex-colum flex-center">
            <a :href="`http://www.minhandedu.com/kyjy/#/?pc=`+this.$route.query.pc"><span>科研教育</span></a>
        </div>
        <div class="header-nav flex-colum flex-center">
            <a :href="`http://www.minhandedu.com/xyfd/#/?pc=`+this.$route.query.pc"><span>学业辅导</span></a>
        </div>
        <div class="header-nav flex-colum flex-center">
            <a :href="`http://www.minhandedu.com/dsjs/#/?pc=`+this.$route.query.pc"><span>师资力量</span></a>
        </div>
        <div class="header-nav flex-colum flex-center">
            <a :href="`http://www.minhandedu.com/xsal/#/?pc=`+this.$route.query.pc"><span>学员案例</span></a>
        </div>
        <div class="header-nav flex-colum flex-center">
            <a :href="`http://www.minhandedu.com/aboutminhandedu/#/?pc=`+this.$route.query.pc"><span>关于我们</span></a>
        </div>
        <div class="header-nav flex-row flex-align-center">
            <my-image :drag="false" :src="require('@/assets/img/home/header/热线.png')"/>
            <div class="tel flex-colum">
                <span style="cursor: auto">英国：<a href="tel: -44-161-932-1110">-44-161-932-1110</a></span>
                <span style="cursor: auto">总部中国：<a href="tel: 4000-972-558">4000-972-558</a></span>
            </div>
        </div>
    </div>
</template>

<script>
import MyImage from '@components/MyImage'
export default {
  data () {
    return {
      headerViewFlag: true
    }
  },
  components: {
    MyImage
  },
  mounted () {
    window.addEventListener('scroll', e => {
      if (!this.headerViewFlag) {
        if (e.srcElement.scrollingElement.scrollTop > 800) {
          this.headerViewFlag = true
        }
      } else {
        if (e.srcElement.scrollingElement.scrollTop <= 800) {
          this.headerViewFlag = false
        }
      }
    })
  }
}
</script>

<style lang="scss" scoped>
    a{
        text-decoration:none;
        color:#fff;
    }
    a:hover{
        text-decoration:none;
        color:  #A6B6F8;
    }
    #header{
        position: fixed;
        top: 0;
        z-index: 999;
        background: rgba(0,0,0,0);
        width: 100%;
        height: 5.19rem;
        transition: background .5s;
        .select:hover .nav_list_child{
            display: block;
            animation: hideIndex 0.3s;
            -moz-animation: hideIndex  0.3s; /* Firefox */
            -webkit-animation: hideIndex  0.3s; /* Safari and Chrome */
            -o-animation: hideIndex  0.3s; /* Opera */
        }
        @keyframes hideIndex{
            0%{ opacity: 0; transform: translateY(8rem, 0) }
            100%{opacity: 1; transform: translateY(0, 0) }
        }
        .header-nav{
            display: inline-flex;
            position: relative;
            color: #fff;
            line-height: 1.38rem;
            padding-left: calc(2.75rem / 2);
            padding-right: calc(2.75rem / 2);
            height: 100%;
            letter-spacing: 0.05rem;
            .nav_list_child{
                display: none;
                ul{
                    padding-top: 1.6rem;
                    position: absolute;
                    list-style-type: none;
                    left:1rem;
                    margin-top:2rem;
                    width: 30rem;
                    height: 6rem;
                    // height:0;
                    background:rgba(0,0,0,0.5);
                    .one{
                        display:flex;
                        flex-direction: row;
                    }
                    .two{
                        margin-top:0.6rem;
                        display:flex;
                        flex-direction: row;
                    }
                }

            }
            .nav_list_child ul li {
                line-height:1.8rem;
                width:2rem;
                padding-left:3rem;
            }
            .nav_list_child ul li a{
                display: block;
                width: 10rem;
            }
            span{
                cursor: pointer;
                user-select: none;
            }
            .tel{
                margin-left: 0.63rem;
                font-size: 0.88rem;
                line-height: 1.25rem;
            }
            .checked{
                // color: #6A328B;
                color:  #A6B6F8;
                // color: #FBBD00;
                font-size: 1.25rem;
                font-family: PingFangSC-Semibold, sans-serif;
                font-weight: bold;

            }
        }

        // .header-nav:hover{
        //     color: #a172f1;
        // }
    }
</style>
