<template>
    <div id="education" class="default-size flex-colum flex-align-center">
        <div style="margin-top: 7.06rem" class="education-title flex-row flex-center">
            <div class="education-icon bgImg-contain"></div>
            <div class="education-title-text">
                <span>品牌简介</span>
            </div>
        </div>
        <div class="container position-rel">
            <div class="education-bg position-abs bgImg-cover"></div>
            <div class="education-content position-abs">
                <div class="content flex-colum flex-align-start">
                    <div class="content-row flex-colum">
                        <div class="content-title">
                            <span>曼汉教育 Minhand Education</span>
                        </div>
                        <div class="content-text">
                            <p>曼汉教育隶属于曼汉集团，多年前在英国曼彻斯特，联合数百名世界名校博士及学界精英创立，并在中国上海、英国伦敦和曼彻斯特设立多个办公室，以平衡全球高等教育资源为华人学子共享为己任。</p>
                        </div>
                    </div>
                    <div class="content-row flex-colum" style="margin-top: 1.5rem">
                        <div class="content-title">
                            <span>品牌理念</span>
                        </div>
                        <div class="content-text" style="margin-top: 0.56rem">
                            <!-- <p style="white-space: pre-wrap;">⼀站式科研学术与新职业教育服务平台<pre/>
                                曼汉教育致⼒优质化配置全球国际教育资源，重于培养学⽣国际化学术思维、学术能⼒和科研能力，助力学生通过教育实现未来提升</p> -->
                                <p style="white-space: pre-wrap;">曼汉教育自成立以来，教育初心始终不变，品牌从“让知识流动起来，让经验传递下去”理念出发，发展成为一个全球一站式科研学术与新职业教育服务平台，建立起中外学术交流、平衡教育资源的桥梁。</p>
                        </div>
                    </div>
                    <div class="content-row flex-colum" style="margin-top: 1.5rem">
                        <div class="content-title">
                            <span>愿景</span>
                        </div>
                        <div class="content-text" style="margin-top: 0.56rem">
                            <p style="white-space: pre-wrap;">构建卓越的高端教育资源共享体系，创造更美好的世界</p>
                        </div>
                    </div>
                    <div class="content-row flex-colum" style="margin-top: 1.5rem">
                        <div class="content-title">
                            <span>使命</span>
                        </div>
                        <div class="content-text" style="margin-top: 0.56rem">
                            <p style="white-space: pre-wrap;">作为平衡高端国际教育资源的践行者，曼汉教育通过教育和科技打通政，产，学，研，用的要素和知识壁垒，实现知识流动，创造价值，为人类和地球的持续发展赋能</p>
                        </div>
                    </div>
                    <div class="content-row flex-colum flex-center" style="margin-top: 1.81rem;width: 100%">
                        <div @click="openZoos" class="know flex-center flex-colum btn-action cur-pointer">
                            <span>想知道更多介绍</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container2 bgImg-contain flex-colum flex-align-center">
            <div id="flag2"></div>
            <div class="title">
                <span>曼汉教育旗下品牌</span>
            </div>
            <div class="subtitle">
                <span>一站式科研学术与新职业教育服务平台</span>
            </div>
            <div class="unit-list flex-row flex-jusity-space-between">
                <div class="unit flex-colum flex-align-center">
                    <div class="icon-doctor bgImg-contain"></div>
                    <div class="unit-title">
                        <span>曼研™</span>
                    </div>
                </div>
                <div class="unit flex-colum flex-align-center">
                    <div class="icon-college bgImg-contain"></div>
                    <div class="unit-title">
                        <span>曼博士™</span>
                    </div>
                </div>
                <div class="unit flex-colum flex-align-center">
                    <div class="icon-know bgImg-contain"></div>
                    <div class="unit-title">
                        <span>曼知®</span>
                    </div>
                </div>
                <div class="unit flex-colum flex-align-center">
                    <div class="icon-offer bgImg-contain"></div>
                    <div class="unit-title">
                        <span>曼职®</span>
                    </div>
                </div>
                <!-- <div class="unit flex-colum flex-align-center">
                    <div class="icon-team bgImg-contain"></div>
                    <div class="unit-title">
                        <span>博士学者联盟</span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import util from '../../utils/util'
export default {
  data () {
    return {
      actionFlag: true,
      actionFlag2: true
    }
  },
  mounted () {
    const _slef = this
    var scrollFun = util.debounce(function () {
      const $el = (document.querySelector('#education')).offsetTop
      const $el2 = (document.querySelector('#flag2')).offsetTop
      const $scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (
        $el - $scrollTop <= 500 &&
                $el - $scrollTop > -500 &&
                _slef.actionFlag) {
        _slef.actionFlag = false
        _slef.action()
      }
      if (
        $el2 - $scrollTop <= 500 &&
                $el2 - $scrollTop > -500 &&
                _slef.actionFlag2) {
        _slef.actionFlag2 = false
        _slef.action2()
      }
    }, 100)
    window.addEventListener('scroll', scrollFun, true)
  },
  methods: {
    action () {
      this.$anime({
        targets: '.education-bg',
        opacity: [0, 1],
        translateX: [-300, 0],
        duration: 1800,
        easing: 'easeInOutBack'

      })
      this.$anime({
        targets: '.education-content',
        opacity: [0, 1],
        translateX: [300, 0],
        duration: 1800,
        easing: 'easeInOutBack'

      })
    },
    action2 () {
      this.$anime({
        targets: '.unit',
        opacity: [0, 1],
        translateX: [300, 0],
        duration: 1800,
        delay: this.$anime.stagger(200),
        easing: 'easeInOutBack'

      })
    },
    openZoos () {
      openJesongChatByGroup(34320, 48064)
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
    #education{
        height: auto;
        background-color: #fff;
        .education-title{
            .education-icon{
                width: 4.38rem;
                height: 4.69rem;
                background-image: url("../../assets/img/home/Home/Education/曼汉教育.png");
            }
            .education-title-text{
                margin-left: 1.19rem;
                user-select: none;
                span{
                    font-size: 2.5rem;
                    font-weight: 600;
                    color: #8071F6;;
                    line-height: 3.5rem;
                }
            }
        }
        .container{
            width: 74.94rem;
            height: 36.13rem;
            margin-top: 4.25rem;
            .education-bg{
                width: 39rem;
                height: 28.88rem;
                opacity: 0;

                background-image: url("../../assets/img/home/Home/Education/bg.png");
                bottom: 0;
                left: 0;
            }
            .education-content{
                width: 54.19rem;
                height: 32.5rem;
                background: #FFFFFF;
                box-shadow: 0rem 0.5rem 1.88rem 0rem rgba(0, 0, 0, 0.12);
                border-radius: 0.25rem;
                opacity: 0;

                top: 0;
                right: 0;
                z-index: 2;

                .content{
                    width: 50rem;
                    height: 23.81rem;
                    margin-top: 2.19rem;
                    margin-left: 2.44rem;
                    .content-row{
                        .content-title{
                            span{
                                font-size: 1.25rem;
                                // font-family: PingFangSC-Semibold, PingFang SC;
                                font-weight: 600;
                                color: #4B4C5E;
                                line-height: 1.13rem;
                            }
                        }
                        .content-text{
                            margin-top: 0.69rem;
                            p{
                                font-size: 1.06rem;
                                font-weight: 400;
                                color: #6A6B7B;
                                line-height: 1.5rem;
                                white-space: pre-wrap;
                            }
                        }
                        .know{
                            width: 11.88rem;
                            height: 2.63rem;
                            background: #8071F6;
                            // box-shadow: 0rem 0.38rem 0.56rem 0rem rgba(106, 50, 139, 0.36);
                            border-radius: 1.75rem;
                            user-select: none;
                            span{
                                font-size: 1.25rem;
                                font-weight: 500;
                                color: #FFFFFF;
                                line-height: 1.75rem;
                            }
                        }
                    }
                }
            }
        }
        .container2{
            width: 100%;
            height: 40.75rem;
            margin-top: 7.44rem;
            background-image: url("../../assets/img/home/Home/Education/bg2.png");
            user-select: none;
            .title{
                margin-top: 5rem;
                span{
                    font-size: 5rem;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 7rem;
                    letter-spacing: 20px;
                    -webkit-text-stroke: 0px #FFFFFF;
                    text-stroke: 0px #FFFFFF;
                }
            }
            .subtitle{
                span{
                    font-size: 1.75rem;
                    font-weight: 500;
                    color: #A6B6F8;
                    line-height: 2.5rem;
                    letter-spacing: 21px;
                }
            }
            .unit-list{
                width: 75.31rem;
                height: auto;
                margin-top: 6.56rem;
                .unit{
                    width: 13.75rem;
                    height: 13.75rem;
                    opacity: 0;
                    background: #8071F6;
                    border-radius: 0.25rem;
                    .unit-title{
                        margin-top: 1.75rem;
                        span{
                            font-size: 1.75rem;
                            font-weight: 400;
                            color: #FFFFFF;
                            line-height: 2.5rem;
                        }
                    }
                    .icon-doctor{
                        width: 6.56rem;
                        height: 5.38rem;
                        margin-top: 2.25rem;
                        background-image: url("../../assets/img/home/Home/Education/博士-2@2x.png");
                    }
                    .icon-college{
                        width: 5.63rem;
                        height: 5.06rem;
                        margin-top: 2.25rem;
                        background-image: url("../../assets/img/home/Home/Education/09院校专区@2x.png");
                    }
                    .icon-know{
                        width: 4.75rem;
                        height: 5.38rem;
                        margin-top: 2.25rem;
                        background-image: url("../../assets/img/home/Home/Education/论文-1@2x.png");
                    }
                    .icon-offer{
                        width: 5.25rem;
                        height: 5.25rem;
                        margin-top: 2.25rem;
                        background-image: url("../../assets/img/home/Home/Education/邀请导师@2x.png");
                    }
                    .icon-team{
                        width: 5.94rem;
                        height: 5.19rem;
                        margin-top: 2.25rem;
                        background-image: url("../../assets/img/home/Home/Education//团队的副本@2x.png");
                    }
                }
            }
        }
    }
</style>
