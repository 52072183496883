var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"default-size flex-colum flex-align-center",attrs:{"id":"education"}},[_vm._m(0),_c('div',{staticClass:"container position-rel"},[_c('div',{staticClass:"education-bg position-abs bgImg-cover"}),_c('div',{staticClass:"education-content position-abs"},[_c('div',{staticClass:"content flex-colum flex-align-start"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"content-row flex-colum flex-center",staticStyle:{"margin-top":"1.81rem","width":"100%"}},[_c('div',{staticClass:"know flex-center flex-colum btn-action cur-pointer",on:{"click":_vm.openZoos}},[_c('span',[_vm._v("想知道更多介绍")])])])])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"education-title flex-row flex-center",staticStyle:{"margin-top":"7.06rem"}},[_c('div',{staticClass:"education-icon bgImg-contain"}),_c('div',{staticClass:"education-title-text"},[_c('span',[_vm._v("品牌简介")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-row flex-colum"},[_c('div',{staticClass:"content-title"},[_c('span',[_vm._v("曼汉教育 Minhand Education")])]),_c('div',{staticClass:"content-text"},[_c('p',[_vm._v("曼汉教育隶属于曼汉集团，多年前在英国曼彻斯特，联合数百名世界名校博士及学界精英创立，并在中国上海、英国伦敦和曼彻斯特设立多个办公室，以平衡全球高等教育资源为华人学子共享为己任。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-row flex-colum",staticStyle:{"margin-top":"1.5rem"}},[_c('div',{staticClass:"content-title"},[_c('span',[_vm._v("品牌理念")])]),_c('div',{staticClass:"content-text",staticStyle:{"margin-top":"0.56rem"}},[_c('p',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v("曼汉教育自成立以来，教育初心始终不变，品牌从“让知识流动起来，让经验传递下去”理念出发，发展成为一个全球一站式科研学术与新职业教育服务平台，建立起中外学术交流、平衡教育资源的桥梁。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-row flex-colum",staticStyle:{"margin-top":"1.5rem"}},[_c('div',{staticClass:"content-title"},[_c('span',[_vm._v("愿景")])]),_c('div',{staticClass:"content-text",staticStyle:{"margin-top":"0.56rem"}},[_c('p',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v("构建卓越的高端教育资源共享体系，创造更美好的世界")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-row flex-colum",staticStyle:{"margin-top":"1.5rem"}},[_c('div',{staticClass:"content-title"},[_c('span',[_vm._v("使命")])]),_c('div',{staticClass:"content-text",staticStyle:{"margin-top":"0.56rem"}},[_c('p',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v("作为平衡高端国际教育资源的践行者，曼汉教育通过教育和科技打通政，产，学，研，用的要素和知识壁垒，实现知识流动，创造价值，为人类和地球的持续发展赋能")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container2 bgImg-contain flex-colum flex-align-center"},[_c('div',{attrs:{"id":"flag2"}}),_c('div',{staticClass:"title"},[_c('span',[_vm._v("曼汉教育旗下品牌")])]),_c('div',{staticClass:"subtitle"},[_c('span',[_vm._v("一站式科研学术与新职业教育服务平台")])]),_c('div',{staticClass:"unit-list flex-row flex-jusity-space-between"},[_c('div',{staticClass:"unit flex-colum flex-align-center"},[_c('div',{staticClass:"icon-doctor bgImg-contain"}),_c('div',{staticClass:"unit-title"},[_c('span',[_vm._v("曼研™")])])]),_c('div',{staticClass:"unit flex-colum flex-align-center"},[_c('div',{staticClass:"icon-college bgImg-contain"}),_c('div',{staticClass:"unit-title"},[_c('span',[_vm._v("曼博士™")])])]),_c('div',{staticClass:"unit flex-colum flex-align-center"},[_c('div',{staticClass:"icon-know bgImg-contain"}),_c('div',{staticClass:"unit-title"},[_c('span',[_vm._v("曼知®")])])]),_c('div',{staticClass:"unit flex-colum flex-align-center"},[_c('div',{staticClass:"icon-offer bgImg-contain"}),_c('div',{staticClass:"unit-title"},[_c('span',[_vm._v("曼职®")])])])])])
}]

export { render, staticRenderFns }