<template>
    <div id="service" class="flex-colum">
        <div class="container-top flex-colum flex-align-center">
            <div style="margin-top: 5rem" class="service-title flex-row flex-center">
                <div class="service-icon bgImg-contain"></div>
                <div class="service-title-text">
                    <span>服务优势</span>
                </div>
            </div>
            <div style="margin-top: 3.25rem;" class="content">
                <div class="row flex-row flex-align-center flex-jusity-space-between">
                    <service-top-unit number="01" advantage="品牌保障" biref="源自英国一站式科研学术服务平台" text="专注高端学术教育，匠心打造精品科研学术课程，只为成就更加优秀的你" :imgPath="pageData.ppbz.path"/>
                    <service-top-unit number="02" advantage="师资保障" biref="师资雄厚覆盖126+个专业领域 " text="曼汉教育联合大牛导师，实践出一套成熟的教学模式，让你所学即所用，收获满点知识技能" :imgPath="pageData.szbz.path"/>
                </div>
                <div style="margin-top: 1.28rem" class="row flex-row flex-align-center flex-jusity-space-between">
                    <service-top-unit number="03" advantage="教学保障" biref="科学课程分阶，细节层级教学 " text="全球知名院校，2000+全博士导师，严格把控教学质量" :imgPath="pageData.jxbz.path"/>
                    <service-top-unit number="04" advantage="服务保障" biref="4V1&5v1服务模式全方位指导 " text="为学员提供全方位的科研学术指导，给与优质的学习体验" :imgPath="pageData.fwbz.path"/>
                </div>
            </div>
            <div id="animefff"></div>
            <div @click="openZoos" class="service-btn flex-colum flex-center cur-pointer btn-action">
                <span>实力助力你的未来</span>
            </div>
        </div>
        <div class="container-bottom bgImg-cover flex-colum flex-align-center">
            <div style="z-index: 2" class="row1 flex-row flex-jusity-space-between flex-align-center">
                <div class="unit position-rel flex-colum flex-center">
                    <div @mouseenter="changeState(0)" :class="`${unitState == 0 ? 'checked' : ''} border-box`">
                    </div>
                    <div @mouseenter="changeState(0)" class="top position-abs">
                        <span>126 +</span>
                    </div>
                    <div @mouseenter="changeState(0)" class="bottom position-abs">
                        <span>覆盖专业领域</span>
                    </div>
                </div>
                <div class="unit position-rel flex-colum flex-center">
                    <div @mouseenter="changeState(1)" :class="`${unitState == 1 ? 'checked' : ''} border-box`">
                    </div>
                    <div @mouseenter="changeState(1)" class="top position-abs">
                        <span>2000 +</span>
                    </div>
                    <div @mouseenter="changeState(1)" class="bottom position-abs">
                        <span>全博士导师</span>
                    </div>
                </div>
                <!-- <div class="unit position-rel flex-colum flex-center">
                    <div @mouseenter="changeState(2)" :class="`${unitState == 2 ? 'checked' : ''} border-box`">
                    </div>
                    <div @mouseenter="changeState(2)" class="top position-abs">
                        <span>98% +</span>
                    </div>
                    <div @mouseenter="changeState(2)" class="bottom position-abs">
                        <span>Offer 斩获率</span>
                    </div>
                </div> -->
                <div class="unit position-rel flex-colum flex-center">
                    <div @mouseenter="changeState(3)" :class="`${unitState == 3 ? 'checked' : ''} border-box`">
                    </div>
                    <div @mouseenter="changeState(3)" class="top position-abs">
                        <span>3000 +</span>
                    </div>
                    <div @mouseenter="changeState(3)" class="bottom position-abs">
                        <span>服务学员</span>
                    </div>
                </div>
                 <div class="unit position-rel flex-colum flex-center">
                    <div @mouseenter="changeState(5)" :class="`${unitState == 5 ? 'checked' : ''} border-box`">
                    </div>
                    <div @mouseenter="changeState(5)" class="top position-abs">
                        <span>32 +</span>
                    </div>
                    <div @mouseenter="changeState(5)" class="bottom position-abs">
                        <span>全球输送学员<br/>国家/地区</span>
                    </div>
                </div>
            </div>
            <!-- <div class="row2 flex-row flex-jusity-space-between flex-align-center"> -->
                <!-- <div class="unit position-rel flex-colum flex-center">
                    <div @mouseenter="changeState(4)" :class="`${unitState == 4 ? 'checked' : ''} border-box`">
                    </div>
                    <div @mouseenter="changeState(4)" class="top position-abs">
                        <span>20 </span><span style="font-size: 1.94rem">万 +</span>
                    </div>
                    <div @mouseenter="changeState(4)" class="bottom position-abs">
                        <span>上课时长</span>
                    </div>
                </div> -->
                <!-- <div class="unit position-rel flex-colum flex-center">
                    <div @mouseenter="changeState(5)" :class="`${unitState == 5 ? 'checked' : ''} border-box`">
                    </div>
                    <div @mouseenter="changeState(5)" class="top position-abs">
                        <span>32 +</span>
                    </div>
                    <div @mouseenter="changeState(5)" class="bottom position-abs">
                        <span>全球输送学员<br/>国家/地区</span>
                    </div>
                </div> -->
                <!-- <div class="unit position-rel flex-colum flex-center">
                    <div @mouseenter="changeState(6)" :class="`${unitState == 6 ? 'checked' : ''} border-box`">
                    </div>
                    <div @mouseenter="changeState(6)" class="top position-abs">
                        <span>98%</span>
                    </div>
                    <div @mouseenter="changeState(6)" class="bottom position-abs">
                        <span>学生推荐率</span>
                    </div>
                </div> -->
            <!-- </div> -->
        </div>
    </div>
</template>

<script>
import util from '../../utils/util'
import ServiceTopUnit from './comm/ServiceTopUnit'
export default {
  data () {
    return {
      actionFlag: true,
      changeFlag: false,
      animeIndex: 0,
      unitState: 0,
      unit_normal: require('../../assets/img/home/Home/Service/normal.png'),
      unit_check: require('../../assets/img/home/Home/Service/check.png'),
      pageData: {
        ppbz: {
          path: require('../../assets/img/home/Home/Service/品牌保障.png')
        },
        szbz: {
          path: require('../../assets/img/home/Home/Service/师资保障.png')
        },
        jxbz: {
          path: require('../../assets/img/home/Home/Service/教学保障.png')
        },
        fwbz: {
          path: require('../../assets/img/home/Home/Service/服务保障.png')
        }
      },
      test: require('../../assets/img/home/Home/Service/品牌保障.png')
    }
  },
  components: {
    ServiceTopUnit
  },
  mounted () {
    const _slef = this
    var scrollFun = util.debounce(function () {
      const $el = (document.querySelector('#animefff')).offsetTop
      const $scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (
        $el - $scrollTop <= 500 &&
                $el - $scrollTop > -500 &&
                _slef.actionFlag) {
        // _slef.action();
      }
    }, 100)
    window.addEventListener('scroll', scrollFun, true)
  },
  methods: {
    action () {
      this.actionFlag = false
      this.$anime({
        targets: ['#flag0', '#flag1', '#flag2', '#flag3', '#flag4', '#flag5', '#flag6'],
        opacity: 1,
        scale: [0.5, 1],
        duration: 1600,
        delay: this.$anime.stagger(200),
        easing: 'easeInOutBack',
        complete: () => {
          this.changeFlag = true
        }
      })
    },
    changeState (index) {
      this.unitState = index
    },
    openZoos () {
      openJesongChatByGroup(34320, 48064)
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
    #service{
        .container-top{
            // width: 120rem;
            height: 57rem;
            background: #F0F2F8;
            border-top: 1px solid white;
            .service-title{
                .service-icon{
                    width: 4.38rem;
                    height: 4.69rem;
                    background-image: url("../../assets/img/home/Home/Service/服务优势.png");
                }
                .service-title-text{
                    margin-left: 1.19rem;
                    user-select: none;
                    span{
                        font-size: 2.5rem;
                        font-weight: 600;
                        color: #8071F6;
                        line-height: 3.5rem;
                    }
                }
            }
            .content{
                width: 75rem;
                height: 32.25rem;
                .row{
                    width: 100%;
                }
            }
            .service-btn{
                width: 13.88rem;
                height: 2.88rem;
                background: #8071F6;
                border-radius: 2.78rem;
                margin-top: 2.94rem;
                // box-shadow: 0,0,1rem,4rem,rgba(0,0,0,.3);
                // box-shadow: 0rem 0.38rem 0.56rem 0rem rgba(106, 50, 139, 0.36);
                span{
                    font-size: 1.25rem;
                    font-weight: 600;
                    color: #FFFFFF;
                    line-height: 1.75rem;
                }
            }
        }
        .container-bottom{
            width: 100%;
            height: 37.5rem;
            background-image: url("../../assets/img/home/Home/Service/bg.png");
            .row1{
                width: 75.03rem;
                height: 17.38rem;
                margin-top: 9.44rem;
            }
            .row2{
                width: 55.78rem;
                height: 17.38rem;
                margin-top: -7.275rem;
            }
            .unit{
                // transform: scale(0.5);
                // opacity: 0;
                user-select: none;
                width: 17.38rem;
                height: 17.38rem;
                // border: 1px solid red;
                cursor: pointer;
                .border-box{
                    width: 12rem;
                    height: 12rem;
                    transform: rotate(45deg);
                    border-radius: 0.6rem;
                    border: 0.15rem solid rgba(166, 182, 248, .3);
                    // background-color: ;
                    // opacity: 0.3;
                    transition: all .33s;
                }
                .checked{
                    border: none !important;
                    background: linear-gradient(180deg, #A6B6F8 0%, #8071F6 100%);
                }
                .top{
                    top: 5.31rem;
                    span{
                        font-size: 2.88rem;
                        font-weight: bold;
                        color: #FFFFFF;
                        line-height: 3.31rem;
                    }
                }
                .bottom{
                    bottom: 5.44rem;
                    span{
                        font-size: 1rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 1.38rem;
                    }
                }
            }
        }
    }
</style>
