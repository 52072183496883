<template>
    <div id="cooperation" class="flex-colum flex-align-center">
        <div style="margin-top: 7.06rem" class="cooperation-title flex-row flex-center">
            <div class="cooperation-icon bgImg-contain"></div>
            <div class="cooperation-title-text">
                <span>合作院校</span>
            </div>
        </div>
        <div class="county-name flex-row flex-align-center flex-jusity-space-between">
            <span :class="`${bigCountySwiperSlectIndex == 0 ? 'checked' : ''}`" @click="bigSwiperSlideTo(0)">英国</span>
            <span :class="`${bigCountySwiperSlectIndex == 1 ? 'checked' : ''}`" @click="bigSwiperSlideTo(1)">美国</span>
            <span :class="`${bigCountySwiperSlectIndex == 2 ? 'checked' : ''}`" @click="bigSwiperSlideTo(2)">澳大利亚</span>
            <span :class="`${bigCountySwiperSlectIndex == 3 ? 'checked' : ''}`" @click="bigSwiperSlideTo(3)">加拿大</span>
            <span :class="`${bigCountySwiperSlectIndex == 4 ? 'checked' : ''}`" @click="bigSwiperSlideTo(4)">新西兰</span>
            <span :class="`${bigCountySwiperSlectIndex == 5 ? 'checked' : ''}`" @click="bigSwiperSlideTo(5)">新加坡</span>
        </div>
        <div class="big-county-swiper flex-row flex-center">
            <div @click="smallSwiperSlidePrev()" v-show="bigCountySwiperSlectIndex == 0 || bigCountySwiperSlectIndex == 3" v-lazy:background-image="require('../../assets/img/home/Home/Cooperation/leftArrow.png')" style="min-width: 2.75rem; min-height: 2.75rem" class="left-arrow bgImg-contain cur-pointer" title="上一项"></div>
            <swiper ref="bigCountySwiper" class="county-swiper flex-row flex-align-center flex-jusity-space-between swiper-no-swiping" :options="bigCoopertaionSchoolSwipeOption">
                <swiper-slide v-for="(item, index) in schoolIconArray" :key="'bigSchoolSwiper_'+index">
                    <div v-if="item.length <= 12" class="school-pic-container">
                        <my-image style="margin-right: 0.58rem" v-for="(item2, index) in item" :key="'schoolIconLoop_'+index" width="11.88rem" height="6.56rem" :src="`${item2}`"/>
                    </div>
                    <div v-else-if="item.length > 12" class="small-county-swiper">
                        <swiper :ref="'smallSwiper'+index" :options="smallCoopertaionSchoolSwipeOption">
                            <swiper-slide v-for="index1 of scalcSmallSwiperCount(item)" :key="'smallSchoolSwiper_'+index1">
                                <div class="small-swiper-cpntainer">
                                    <my-image v-if="item[(index2-1)+(12 * (index1-1))]" style="margin-right: 0.58rem" v-for="index2 of 12" :key="'schoolIconLoop2_'+index2" width="11.88rem" height="6.56rem" :src="`${item[(index2-1)+(12 * (index1-1))]}`"/>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </swiper-slide>
            </swiper>
            <div @click="smallSwiperSlideNext()" v-show="bigCountySwiperSlectIndex == 0 || bigCountySwiperSlectIndex == 3" v-lazy:background-image="require('../../assets/img/home/Home/Cooperation/rightArrow.png')" style="min-width: 2.75rem; min-height: 2.75rem" class="left-arrow bgImg-contain cur-pointer" title="下一项"></div>
        </div>
        <!-- <div class="small-swiper-pagiation position-rel flex-row flex-align-center flex-jusity-space-around">
            <div class="dot" v-for="index3 of scalcPaginationCount" :key="'pagi_'+index3"></div>
        </div> -->
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import MyImage from '../MyImage'
export default {
  data () {
    return {
      bigCountySwiperSlectIndex: 0,
      bigCoopertaionSchoolSwipeOption: {
        initialSlide: 0,
        noSwiping: true
      },
      smallCoopertaionSchoolSwipeOption: {
      },
      schoolIconArray: {
        yg: [
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E4%B8%9C%E8%8B%B1%E5%90%89%E5%88%A9%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E4%BC%A6%E6%95%A6%E5%9B%BD%E7%8E%8B%E5%AD%A6%E9%99%A2%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E4%BC%A6%E6%95%A6%E5%A4%A7%E5%AD%A6%E5%AD%A6%E9%99%A2%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E4%BC%A6%E6%95%A6%E5%A4%A7%E5%AD%A6%E7%9A%87%E5%AE%B6%E9%9C%8D%E6%B4%9B%E5%A8%81%E5%AD%A6%E9%99%A2%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E4%BC%A6%E6%95%A6%E6%94%BF%E6%B2%BB%E7%BB%8F%E6%B5%8E%E5%AD%A6%E9%99%A2%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E4%BC%AF%E6%98%8E%E7%BF%B0%E5%A4%A7%E5%AD%A6%20%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E5%85%B0%E5%8D%A1%E6%96%AF%E7%89%B9%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E5%88%A9%E5%85%B9%E5%A4%A7%E5%AD%A6%20%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E5%89%91%E6%A1%A5%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E5%8D%8E%E5%A8%81%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E5%8D%97%E5%AE%89%E6%99%AE%E9%A1%BF%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E5%93%88%E7%8F%80%E4%BA%9A%E5%BD%93%E6%96%AF%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E5%9F%83%E5%85%8B%E5%A1%9E%E7%89%B9%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E5%B7%B4%E6%96%AF%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E5%B8%83%E9%87%8C%E6%96%AF%E6%89%98%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E5%B8%9D%E5%9B%BD%E7%90%86%E5%B7%A5%E5%AD%A6%E9%99%A2%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E6%8B%89%E5%A4%AB%E5%A0%A1%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E6%9B%BC%E5%BD%BB%E6%96%AF%E7%89%B9%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E6%9D%9C%E4%BC%A6%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E6%A0%BC%E6%8B%89%E6%96%AF%E5%93%A5%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E7%88%B1%E4%B8%81%E5%A0%A1%E5%A4%A7%E5%AD%A6%402x%281%29.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E7%88%B1%E4%B8%81%E5%A0%A1%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E7%89%9B%E6%B4%A5%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E7%BA%A6%E5%85%8B%E5%A4%A7%E5%AD%A6%20%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E7%BA%BD%E5%8D%A1%E6%96%AF%E5%B0%94%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E7%BC%96%E7%BB%84%202%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E8%AF%BA%E4%B8%81%E6%B1%89%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E8%B0%A2%E8%8F%B2%E5%B0%94%E5%BE%B7%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E9%82%93%E8%BF%AA%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E8%8B%B1%E6%A0%BC%E5%85%B0/png/%E9%98%BF%E4%BC%AF%E4%B8%81%E5%A4%A7%E5%AD%A6%402x.png',,
        ],
        mg: [
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E7%BE%8E%E5%9B%BD/png/%E5%93%88%E4%BD%9B%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E7%BE%8E%E5%9B%BD/png/%E5%93%A5%E4%BC%A6%E6%AF%94%E4%BA%9A%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E7%BE%8E%E5%9B%BD/png/%E5%AE%BE%E5%A4%95%E6%B3%95%E5%B0%BC%E4%BA%9A%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E7%BE%8E%E5%9B%BD/png/%E5%B8%83%E6%9C%97%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E7%BE%8E%E5%9B%BD/png/%E5%BA%B7%E5%A5%88%E5%B0%94%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E7%BE%8E%E5%9B%BD/png/%E6%99%AE%E6%9E%97%E6%96%AF%E9%A1%BF%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E7%BE%8E%E5%9B%BD/png/%E8%80%B6%E9%B2%81%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E7%BE%8E%E5%9B%BD/png/%E8%BE%BE%E7%89%B9%E8%8C%85%E6%96%AF%E5%AD%A6%E9%99%A2%402x.png'

        ],
        adly: [
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/png/%E5%A2%A8%E5%B0%94%E6%9C%AC%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/png/%E6%82%89%E5%B0%BC%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/png/%E6%96%B0%E5%8D%97%E5%A8%81%E5%B0%94%E5%A3%AB%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/png/%E6%98%86%E5%A3%AB%E5%85%B0%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/png/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A%E5%9B%BD%E7%AB%8B%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/png/%E8%8E%AB%E7%BA%B3%E4%BB%80%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/png/%E8%A5%BF%E6%BE%B3%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/png/%E9%98%BF%E5%BE%B7%E8%8E%B1%E5%BE%B7%E5%A4%A7%E5%AD%A6%402x.png'

        ],
        jnd: [
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E4%B8%8D%E5%88%97%E9%A2%A0%E5%93%A5%E4%BC%A6%E6%AF%94%E4%BA%9A%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E5%A5%B3%E7%8E%8B%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E6%8B%89%E7%93%A6%E5%B0%94%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E6%9B%BC%E5%B0%BC%E6%89%98%E5%B7%B4%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E6%B8%A5%E5%A4%AA%E5%8D%8E%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E6%BB%91%E9%93%81%E5%8D%A2%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E7%BC%96%E7%BB%84%204%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E7%BC%96%E7%BB%84%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E8%90%A8%E6%96%AF%E5%96%80%E5%BD%BB%E6%B8%A9%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E8%92%99%E7%89%B9%E5%88%A9%E5%B0%94%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E8%BE%BE%E5%B0%94%E8%B1%AA%E6%96%AF%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E9%98%BF%E5%B0%94%E4%BC%AF%E5%A1%94%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E9%BA%A6%E5%85%8B%E9%A9%AC%E6%96%AF%E7%89%B9%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E5%8A%A0%E6%8B%BF%E5%A4%A7/png/%E9%BA%A6%E5%90%89%E5%B0%94%E5%A4%A7%E5%AD%A6%402x.png'

        ],
        xxl: [
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E8%A5%BF%E5%85%B0/png/%E5%9D%8E%E7%89%B9%E4%BC%AF%E9%9B%B7%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E8%A5%BF%E5%85%B0/png/%E5%A5%A5%E5%85%8B%E5%85%B0%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E8%A5%BF%E5%85%B0/png/%E5%A5%A5%E5%85%8B%E5%85%B0%E7%90%86%E5%B7%A5%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E8%A5%BF%E5%85%B0/png/%E5%A5%A5%E5%A1%94%E5%93%A5%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E8%A5%BF%E5%85%B0/png/%E6%80%80%E5%8D%A1%E6%89%98%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E8%A5%BF%E5%85%B0/png/%E6%9E%97%E8%82%AF%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E8%A5%BF%E5%85%B0/png/%E6%A2%85%E8%A5%BF%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E8%A5%BF%E5%85%B0/png/%E7%BB%B4%E5%A4%9A%E5%88%A9%E4%BA%9A%E5%A4%A7%E5%AD%A6%402x.png'

        ],
        xjp: [
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E5%8A%A0%E5%9D%A1/png/%E6%96%B0%E5%8A%A0%E5%9D%A1%E4%B9%89%E5%AE%89%E7%90%86%E5%B7%A5%E5%AD%A6%E9%99%A2%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E5%8A%A0%E5%9D%A1/png/%E6%96%B0%E5%8A%A0%E5%9D%A1%E5%8D%97%E6%B4%8B%E7%90%86%E5%B7%A5%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E5%8A%A0%E5%9D%A1/png/%E6%96%B0%E5%8A%A0%E5%9D%A1%E5%8D%97%E6%B4%8B%E7%90%86%E5%B7%A5%E5%AD%A6%E9%99%A2%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E5%8A%A0%E5%9D%A1/png/%E6%96%B0%E5%8A%A0%E5%9D%A1%E5%9B%BD%E7%AB%8B%E5%A4%A7%E5%AD%A6%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E5%8A%A0%E5%9D%A1/png/%E6%96%B0%E5%8A%A0%E5%9D%A1%E7%90%86%E5%B7%A5%E5%AD%A6%E9%99%A2%402x.png',
          'https://minhandmini.oss-cn-shanghai.aliyuncs.com/minhandeduAG/partnerSchool/%E6%96%B0%E5%8A%A0%E5%9D%A1/png/%E6%96%B0%E5%8A%A0%E5%9D%A1%E7%AE%A1%E7%90%86%E5%A4%A7%E5%AD%A6%402x.png'

        ]
      }
    }
  },
  computed: {
    scalcPaginationCount () {
      return this.bigCountySwiperSlectIndex == 0 ? 3 : this.bigCountySwiperSlectIndex == 3 ? 2 : 0
    }
  },
  methods: {
    scalcSmallSwiperCount (item) {
      return Math.ceil(item.length / 12)
    },
    bigSwiperSlideTo (index) {
      new Promise((resolve, reject) => { resolve(this.$refs.bigCountySwiper.$swiper.slideTo(index, 600)) }).then(res => {
        this.bigCountySwiperSlectIndex = index
      })
    },
    smallSwiperSlidePrev () {
      switch (this.bigCountySwiperSlectIndex) {
        case 0:
          this.$refs.smallSwiperyg[0].$swiper.slidePrev()
          break
        case 3:
          this.$refs.smallSwiperjnd[0].$swiper.slidePrev()
          break
        default:
          break
      }
    },
    smallSwiperSlideNext () {
      switch (this.bigCountySwiperSlectIndex) {
        case 0:
          this.$refs.smallSwiperyg[0].$swiper.slideNext()
          break
        case 3:
          this.$refs.smallSwiperjnd[0].$swiper.slideNext()
          break
        default:
          break
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    MyImage
  }
}
</script>

<style lang="scss" scoped>
    #cooperation{
        .cooperation-title{
            .cooperation-icon{
                width: 4.38rem;
                height: 4.69rem;
                background-image: url("../../assets/img/home/Home/Cooperation/合作院校.png");
            }
            .cooperation-title-text{
                margin-left: 1.19rem;
                user-select: none;
                span{
                    font-size: 2.5rem;
                    font-weight: 600;
                    color: #8071F6;
                    line-height: 3.5rem;
                }
            }
        }
        .county-name{
            width: 75.19rem;
            height: 1.7rem;
            margin-top: 2.69rem;
            cursor: pointer;
            user-select: none;
            span{
                display: inline-block;
                height: 1.64rem;
                font-size: 1.13rem;
                font-weight: 600;
                color: #434343;
                line-height: 1.56rem;
                transition: color .3s;
            }
            .checked{
                border-bottom: 0.2rem solid #8071F6;
                color: #8071F6;
            }
        }
        .big-county-swiper{
            width: 88.31rem;
            margin-top: 3.13rem;
            .county-swiper{
                width: 75rem;
                height: 13.94rem;
                .school-pic-container{
                    user-select: none;
                    width: 75rem;
                    height: 13.94rem;
                    // border: 1px solid black;
                    // overflow: hidden;
                }
                .small-county-swiper{
                    width: 75rem;
                    height: 18rem;
                    // border: 1px solid black;
                    // overflow: hidden;
                    .small-swiper-container{
                        user-select: none;
                        width: 75rem;
                        height: 15rem;
                        // overflow: hidden;
                    }
                }
            }
        }
        .small-swiper-pagiation{
            width: 10rem;
            height: 0.5rem;
            margin: 0 auto;
            z-index: 10;
            margin-top: 0.73rem;
            .dot{
                width: 0.5rem;
                height: 0.5rem;
                background-color: rgba(0,0,0,.12);
                border-radius: 50%;
            }
            .checkDot{
                background-color: rgba(0,0,0,.4) !important;
            }
        }
    }
</style>
