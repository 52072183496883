<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted () {
    const url = document.referrer
    if (this.$route.query.pc) {
      this.$cookies.set('xiaocms_pc', this.$route.query.pc, '1d')
      console.log('1111', this.$route.query.pc)
    } else {
      this.$cookies.set('xiaocms_pc', '', '1d')
    }
    if (url.indexOf('\/')) {
      this.$cookies.set('xiaocms_http_referer', url, '1d')
    } else {
      this.$cookies.set('xiaocms_http_referer', '', '1d')
    }
  },
  destroyed () {
    this.$cookies.remove('xiaocms_pc')
    this.$cookies.remove('xiaocms_http_referer')
  }
}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}
@media (max-width: 1920px) {
  body,html{
    font-size: 16px;
  }
}
@media (max-width: 1680px) {
  body,html{
    font-size: 14px;
  }
}
@media (max-width: 1366px) {
  body,html{
    font-size: 13px;
  }
}
@media (max-width: 1360px) {
  body,html{
    font-size: 13px;
  }
}
@media (max-width: 1280px) {
  body,html{
    font-size: 10px;
  }
}
@media (max-width: 1024px) {
  body,html{
    font-size: 9px;
  }
}
body,html{
  font-family: PingFangSC-Regular, sans-serif;
  color: #333;
  // font-size: 16px;
}
</style>
