<template>
    <div id="student-share" class="flex- colum bgImg-cover default-size">
        <div class="student-share-title flex-row flex-align-center">
            <my-image width="4.17rem" height="4.58em"  :src="require('../../assets/img/home/Home/StudentShare/xzxj.png')"></my-image><span style="margin-left:1.25rem;">对于曼汉教育，学长学姐这么说</span>
        </div>
        <div class="student-share-text-container flex-colum flex-center">
            <div @mouseenter="stop" @mouseleave="start" id="animeFlag">
                <div class="content-pane flex-colum">
                    <div style="margin-left: 3.94rem; margin-top: 3.31rem;" class="content-row flex-row">
                        <div class="offer-pane flex-row flex-align-center">
                            <i class="icon-offer bgImg-contain"></i>
                            <span>专业：{{checkStudentInfo.offer}}</span>
                        </div>
                        <div class="result-pane">
                            <i class="icon-result bgImg-contain"></i>
                            <span>{{checkStudentInfo.result}}</span>
                        </div>
                    </div>
                    <div style="margin-left: 1.81rem;margin-top: 1rem;margin-bottom: -1.88rem" class="content-row">
                        <my-image width="1.44rem" height="1.88rem" :src="require('../../assets/img/home/Home/StudentShare/“.png')"/>
                    </div>
                    <div style="margin-left: 4.06rem" class="content-row">
                        <div class="content flex-colum">
                            <div class="subtitle">
                                <span>学员评价:</span>
                            </div>
                            <div class="content-text">
                                <span>{{checkStudentInfo.comment}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="margin-right: 2rem;margin-top: -0.4rem;" class="content-row">
                        <my-image style="float: right" width="1.44rem" height="1.88rem" :src="require('../../assets/img/home/Home/StudentShare/”.png')"/>
                    </div>
                </div>
                <div class="little-pane flex-row flex-align-start flex-jusity-space-between">
                    <div :style="`opacity: ${studentSwiperIndex == 0 ? '1' : 0}`" class="nav t1 bgImg-contain"></div>
                    <div :style="`opacity: ${studentSwiperIndex == 1 ? '1' : 0}`" class="nav t1 bgImg-contain"></div>
                    <div :style="`opacity: ${studentSwiperIndex == 2 ? '1' : 0}`" class="nav t1 bgImg-contain"></div>
                    <div :style="`opacity: ${studentSwiperIndex == 3 ? '1' : 0}`" class="nav t1 bgImg-contain"></div>
                    <div :style="`opacity: ${studentSwiperIndex == 4 ? '1' : 0}`" class="nav t1 bgImg-contain"></div>
                    <div :style="`opacity: ${studentSwiperIndex == 5 ? '1' : 0}`" class="nav t1 bgImg-contain"></div>
                </div>
            </div>
            <div @mouseenter="stop" @mouseleave="start" class="student-share-swiper">
                <swiper ref="studentSwiper" class="swiper" :options="studentSwiperOption">
                    <swiper-slide class="slide" v-for="(item,index) in studentInfos" :key="'studentSwiper_'+index">
                        <div @click="changeStudentInfo(index)" class="student-share-nav flex-colum">
                            <div class="pic-box bgImg-contain flex-colum flex-center">
                                <my-image style="margin-top: 0.1rem" width="9.38rem" height="10.25rem" :src="`${item.avatar}`"/>
                            </div>
                            <div class="student-name flex-colum flex-center">
                                <span>{{item.name}}</span>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>

<script>
import MyImage from '../MyImage'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

// const anime = require("../../../node_modules/")
export default {
  data () {
    return {
      studentSwiperOption: {
        slidesPerView: 6,
        centeredSlidesBounds: true
        // loop: true,
      },
      interval: {},
      studentSwiperIndex: 0,
      realStudentSwiperIndex: 0,
      animeFlag: true,
      studentInfos: [
        {
          name: 'Brenda 同学',
          avatar: require('../../assets/img/home/Home/StudentShare/hayden.png'),
          offer: '伯明翰大学，金融硕士',
          result: '辅导成果：斩获西交利物浦大学博士 offer',
          comment: '曼汉的老师 Dr. Cui 真的非常专业，不仅辅导我写 RP，而且注重我的学术能力和思维方式的培养，整个过程对我有很大的帮助，真的很棒！DIY 很容易就走错路，但是有同方向的老师带真的很踏实！'
        },
        {
          name: 'Irma 同学',
          avatar: require('../../assets/img/home/Home/StudentShare/eddie.png'),
          offer: '布里斯托大学，会计硕士',
          result: '辅导成果：斩获香港中文大学博士 offer',
          comment: '我第一年 DIY 失败，套磁没有拿到任何回复，第二年通过对比了解，找到了曼汉，跟着黄劲博士重新撰写研究计划，反复修改，最后终于拿到了爱丁堡大学和香港中文大学的博士 offer！'
        },
        {
          name: 'Xandy 同学',
          avatar: require('../../assets/img/home/Home/StudentShare/alan.png'),
          offer: '卡迪夫大学，医学硕士在读',
          result: '辅导成果：斩获帝国理工大学博士 offer',
          comment: '虽然我的在校成绩不是很突出，但是了解到了曼汉教育的背景提升项目，跟着 Norman 博士 tutor 深入参与了科研之后，让我的博士申请履历也加分了很多，在他们的帮助下我还发表了一线的学术期刊，最后顺利拿到了帝国理工大学的博士offer！'
        },
        {
          name: 'Winston 同学',
          avatar: require('../../assets/img/home/Home/StudentShare/eilian.png'),
          offer: '莱斯特大学，经济学硕士',
          result: '辅导成果：斩获伦敦大学学院博士 offer',
          comment: '跨专业申请博士的同学注意了！我真心推荐曼汉教育的背景提升项目！跨专业申博是一件非常非常难的事情，没有相关科研背景，导师是不敢轻易招你的！但是真的不用放弃，曼汉有很多前端的科研项目，可以让你提前熟悉你的申请领域，对撰写研究计划和面试都很有帮助！'
        },
        {
          name: 'Eilian 同学',
          avatar: require('../../assets/img/home/Home/StudentShare/bella.png'),
          offer: '圣安德鲁斯大学，计算机本科在读',
          result: '辅导类别：课中辅导',
          comment: '曼知的老师 Brown 很专业，能抓住我课程的重点，并且根据课程难点逐个帮我击破，讲解的很清晰，也会发给我很多论文以及专业课会用到的资料，干货很多。'
        },
        {
          name: 'Alan 同学',
          avatar: require('../../assets/img/home/Home/StudentShare/steve.png'),
          offer: '爱丁堡大学，教育学硕士',
          result: '辅导类别：论文辅导',
          comment: '本来以为我都要挂科了！曼知的 lucinda 老师人美心善，第一时间帮我找到同专业博士导师 Julia，讲解课程的重点和难点，手把手教我写了专业课论文，论文的每个部分都帮我细心修改，最后这门课的论文拿到68分，比班里的外国同学还要高！'
        }
      ],
      checkStudentInfo: {
        name: 'Brenda 同学',
        avatar: require('../../assets/img/home/Home/StudentShare/hayden.png'),
        offer: '伯明翰大学，金融硕士',
        result: '辅导成果：斩获西交利物浦大学博士 offer',
        comment: '曼汉的老师 Dr. Cui 真的非常专业，不仅辅导我写 RP，而且注重我的学术能力和思维方式的培养，整个过程对我有很大的帮助，真的很棒！DIY 很容易就走错路，但是有同方向的老师带真的很踏实！'
      }
    }
  },
  components: {
    MyImage,
    Swiper,
    SwiperSlide
  },
  mounted () {
    this.interval = setInterval(() => {
      this.changeStudentInfo(this.studentSwiperIndex + 1 >= 6 ? this.studentSwiperIndex = 0 : this.studentSwiperIndex += 1)
    }, 8000)
  },
  methods: {
    start () {
      // console.log('start');
      this.interval = setInterval(() => {
        this.changeStudentInfo(this.studentSwiperIndex + 1 >= 6 ? this.studentSwiperIndex = 0 : this.studentSwiperIndex += 1)
      }, 8000)
    },
    stop () {
      // console.log('stop');
      clearInterval(this.interval)
    },
    changeStudentInfo (index) {
      if (this.animeFlag) {
        this.animeFlag = false
        this.$anime({
          targets: '#animeFlag',
          translateY: [0, 200],
          translateX: [0, 250 * (this.studentSwiperIndex - 2)],
          scale: [1, 0],
          opacity: [1, 0],
          duration: 180,
          easing: 'linear',
          complete: (anime) => {
            this.checkStudentInfo = {
              ...this.studentInfos[index]
            }
            this.studentSwiperIndex = Math.abs(index - this.$refs.studentSwiper.$swiper.activeIndex)
            this.$anime({
              targets: '#animeFlag',
              translateY: 0,
              translateX: [250 * (index - 2), 0],
              scale: 1,
              opacity: 1,
              duration: 800,
              delay: 200,
              easing: 'easeInOutBack',
              complete: (anime) => {
                this.animeFlag = true
              }
            })
          }
        })
      }
      // console.log(this.animeData)
    }
  }
}
</script>

<style lang="scss" scoped>
    #student-share{
        width: 100%;
        height: 52rem;
        background-image: url("../../assets/img/home/Home/StudentShare/bg.png");
        border-top: 1px solid black;
        .student-share-title{
            margin-top: 4.63rem;
            margin-left: 22.5rem;
            i{
                display: inline-block;
                width: 0.5rem;
                height: 2.56rem;
                background: #8071F6;
                border-radius: 0.25rem;
                margin-right: 1.5rem;
            }
            span{
                font-size: 2.5rem;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 3.5rem;
            }
        }
        .student-share-text-container{
            margin-top: 3.25rem;
            .content-pane{
                width: 74.88rem;
                height: 21rem;
                border-radius: 0.2rem;
                background-color: #fff;
                position: relative;
                z-index: 2;
                .content-row{
                    user-select: none;
                    .offer-pane{
                        .icon-offer{
                            display: inline-block;
                            width: 1.19rem;
                            height: 1.19rem;
                            background-image: url("../../assets/img/home/Home/StudentShare/专业.png");
                            margin-right: 0.65rem;
                        }
                        span{
                            font-size: 1.25rem;
                            font-weight: 600;
                            color: #4B4C5E;
                            line-height: 1.75rem;
                        }
                    }
                    .result-pane{
                        margin-left: 3.5rem;
                        .icon-result{
                            width: 1.13rem;
                            height: 1.19rem;
                            display: inline-block;
                            background-image: url("../../assets/img/home/Home/StudentShare/成果.png");
                            margin-right: 0.65rem;
                            margin-bottom: -0.24rem;
                        }
                        span{
                            font-size: 1.25rem;
                            font-weight: 600;
                            color: #4B4C5E;
                            line-height: 1.75rem;
                        }
                    }
                    .left-d{
                        display: inline-block;
                        font-size: 4.25rem;
                        font-family: DINAlternate-Bold, DINAlternate;
                        font-weight: bold;
                        color: #8071F6;
                        line-height: 4.94rem;
                    }
                    .content{
                        .subtitle{
                            span{
                                font-size: 1.25rem;
                                font-weight: 600;
                                color: #8071F6;
                                line-height: 1.75rem;
                            }
                        }
                        .content-text{
                            max-width: 67.69rem;
                            span{
                                font-size: 1.25rem;
                                font-weight: 400;
                                color: #4B4C5E;
                                line-height: 2.5rem;
                            }
                        }
                    }
                }
            }
            .little-pane{
                margin: 0 auto;
                width: 66rem;
                .nav{
                    transition: all .18s;
                    width: 2.75rem;
                    height: 1.88rem;
                    background-image: url("../../assets/img/home/Home/StudentShare/路径 6@2x.png");
                }
                .t1{
                }
            }
            .student-share-swiper{
                width: 75rem;
                height: 13.56rem;
                margin: 0 auto;
                margin-top: 1.69rem;
                padding-left: 1.6rem;
                .swiper{
                    width: 75.5rem;
                    .slide{

                    }
                }
                .student-share-nav{
                    cursor: pointer;
                    width: 9.94rem;
                    height: 13.56rem;
                    .pic-box{
                        width: 9.94rem;
                        height: 10.88rem;
                        transition: all .3s;
                    }
                    .pic-box:hover{
                        background-image: url("../../assets/img/home/Home/StudentShare/蒙版.png")
                    }
                    .student-name{
                        margin-top: 0.94rem;
                        span{
                            word-break: keep-all;
                            font-size: 1.25rem;
                            font-weight: 600;
                            color: #FFFFFF;
                            line-height: 1.75rem;
                        }
                    }
                }
            }
        }
    }
</style>
