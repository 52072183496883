<template>
    <div id="teacher-power" class="default-size flex-colum flex-align-center">
        <div style="margin-top: 7.06rem" class="teacher-power-title flex-row flex-center">
            <div class="teacher-power-icon bgImg-contain"></div>
            <div class="teacher-power-title-text">
                <span>师资力量</span>
            </div>
        </div>
        <div class="teacher-power-top-container flex-colum flex-center">
            <div class="top-center-container flex-colum flex-center position-rel">
                <div class="center-top-pane flex-row flex-center">
                    <div @mouseenter="changeCricState(1)" :style="`background-image: url(${require('../../assets/img/home/Home/TeacherPower/路径 5.png')}); margin-right: -1.67rem; `" :class="`pane-nav1 bgImg-contain anime ${checkedIndex == 1 ? 'anime2': ''}`">
                        <my-image style="margin-top: 3.19rem; margin-left: 4.63rem;" :lazyload="true" :staticFlag="true" width="2.06rem" height="2.06rem" :src="require('../../assets/img/home/Home/TeacherPower/凯诺师资.png')"/>
                    </div>
                    <div @mouseenter="changeCricState(2)" :style="`background-image: url(${require('../../assets/img/home/Home/TeacherPower/路径 5 (1).png')}); margin-top: 1.63rem;`" :class="`pane-nav2 bgImg-contain anime ${checkedIndex == 2 ? 'anime2': ''}`">
                        <my-image style="margin-top: 5.13rem; margin-left: 5.94rem" :lazyload="true" :staticFlag="true" width="1.94rem" height="1.94rem" :src="require('../../assets/img/home/Home/TeacherPower/院校班级.png')"/>
                    </div>
                </div>
                <div style="margin-top: -2.5rem" class="center-top-pane flex-row flex-center">
                    <div @mouseenter="changeCricState(3)" :style="`background-image: url(${require('../../assets/img/home/Home/TeacherPower/路径 5 (3).png')}); margin-right: -1.67rem;margin-top: -0.6rem`" :class="`pane-nav2 bgImg-contain anime ${checkedIndex == 3 ? 'anime2': ''}`">
                        <my-image style="margin-top: 5.69rem;margin-left: 3.87rem;" :lazyload="true" :staticFlag="true" width="2rem" height="1.94rem" :src="require('../../assets/img/home/Home/TeacherPower/经验.png')" />
                    </div>
                    <div @mouseenter="changeCricState(4)" :style="`background-image: url(${require('../../assets/img/home/Home/TeacherPower/路径 5 (2).png')}); margin-top: 0.6rem; width: 12.56rem`" :class="`pane-nav1 bgImg-contain anime ${checkedIndex == 4 ? 'anime2': ''}`">
                        <my-image style="margin-top: 5.19rem;margin-left: 6.06rem;" :lazyload="true" :staticFlag="true" width="2rem" height="2rem" :src="require('../../assets/img/home/Home/TeacherPower/领域.png')" />
                    </div>
                </div>
                <div style="top: -1.81rem; left: -26.75rem" :class="`center-text-box flex-colum flex-align-center position-abs ${checkedIndex == 1 ? 'checked' : ''}`">
                    <div class="title">
                        <span>签约海内外科研导师2000+</span>
                    </div>
                    <div style="width: 30.38rem" class="text">
                        <span>科研导师来自包含牛津、剑桥、耶鲁等全球知名院校，由博士、博士后、讲师、教授组成</span>
                    </div>
                </div>
                <div style="top: -1.81rem; right: -26.75rem" :class="`center-text-box flex-colum flex-align-center position-abs ${checkedIndex == 2 ? 'checked' : ''}`">
                    <div class="title">
                        <span>覆盖全球60个国家或地区</span>
                    </div>
                    <div style="width: 29.19rem" class="text">
                        <span>科研导师可辅导申请学校分布于美、加、英、澳、新、日等60多个国家超500所高校。</span>
                    </div>
                </div>
                <div style="bottom: -1.63rem; left: -26.75rem" :class="`center-text-box flex-colum flex-align-center position-abs ${checkedIndex == 3 ? 'checked' : ''}`">
                    <div class="title">
                        <span>具有多年辅导经验</span>
                    </div>
                    <div style="width: 30.5rem" class="text">
                        <span>科研导师均具有丰富的带教、辅导经验，具有多年辅导经验，能因材施教定制个人全程辅导方案。</span>
                    </div>
                </div>
                <div style="bottom: -1.63rem; right: -26.75rem" :class="`center-text-box flex-colum flex-align-center position-abs ${checkedIndex == 4 ? 'checked' : ''}`">
                    <div class="title">
                        <span>涵盖约126+个研究领域</span>
                    </div>
                    <div style="width: 29.19rem" class="text">
                        <span>科研导师能指导专业涵盖文、理、工、商、艺术等所有学科，可提供大量科研课题供学员选择。</span>
                    </div>
                </div>
            </div>
            <div class="teacher-doctor-swiper-container flex-colum flex-align-center">
                <div class="swiper-top flex-row flex-jusity-space-between flex-align-center">
                    <div class="left-arrow bgImg-contain cur-pointer" title="下一项"></div>
                    <div class="doctor-swiper">
                        <swiper :options="doctorSwipeOption">
                            <swiper-slide v-for="(item,index) in teacherInfos" :key="'doctor_'+index">
                                <div :class="`doctor-swiper-nav flex-colum position-rel`">
                                    <div class="doctor-swiper-nav-img bgImg-contain flex-colum flex-align-center" :style="`background-image: url(${item.avatar})`">
                                        <div class="txt-span">
                                            <span>{{item.redirect}}</span>
                                        </div>
                                    </div>
                                    <div class="doctor-name">
                                        <span>{{item.name}}</span>
                                    </div>
                                    <div class="doctor-offer">
                                        <span>{{item.offer}}</span>
                                    </div>
                                    <div class="doctor-biref">
                                        <span>{{item.biref}}</span>
                                    </div>
                                    <div @click="openZoos" class="service-btn flex-colum flex-center position-abs">
                                        <span>预约老师</span>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                    <div class="right-arrow bgImg-contain cur-pointer" title="下一项"></div>
                </div>
                <div class="swiper-bottom flex-colum flex-align-center">
                    <div class="pagination-box">
                        <div class="doctor-pagination flex-row flex-align-center flex-jusity-space-between"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MyImage from '../MyImage'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  data () {
    return {
      checkedIndex: 0,
      doctorSwipeOption: {
        autoplay: {
          delay: 3800,
          waitForTransition: true

        },
        slidesPerView: 3,
        initialSlide: 0,
        // centeredSlides: true,
        // centeredSlidesBounds: true,
        // loop: true,
        navigation: {
          nextEl: '.swiper-top .right-arrow',
          prevEl: '.swiper-top .left-arrow'

        },
        pagination: {
          el: '.doctor-pagination'
        },
        on: {
          slideChangeTransitionEnd (swiper) {
            this.doctorRealIndex = swiper.realIndex
            swiper.update()
            // console.log(this.doctorRealIndex);
          }
        }
        // slideActiveClass: 'doctor-swiper-nav-check',
        // slideClass: 'doctor-swiper-nav'
      },
      teacherInfos: [
        {
          name: 'Polly',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/Polly.png'),
          redirect: '社会科学',
          offer: '纽约大学教育心理学博士',
          biref: '研究领域：阅读认知以及语言学、教育心理学'
        },
        {
          name: 'Julia',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/Julia.png'),
          redirect: '社会科学',
          offer: '利兹大学语言学博士',
          biref: '研究领域：语言学、心理语言学、语料库语言学、应用语言学'
        },
        {
          name: 'Edmund',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/Edmund1.png'),
          redirect: '人文艺术',
          offer: '香港中文大学文化产业管理博士',
          biref: '研究领域：中国传统音乐研究 世界民族音乐研究'
        },
        {
          name: 'Eve',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/Eve.png'),
          redirect: '商科',
          offer: '曼彻斯特大学商业管理与金融博士',
          biref: '研究领域：绿色金融、绿色债券公司融资'
        },
        {
          name: 'Francis',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/Francis.png'),
          redirect: '数学与自然科学',
          offer: '北卡罗来纳大学教堂山分校数学博士',
          biref: '研究领域：数学，统计，优化，神经网络'
        },
        {
          name: 'Allison',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/Allison1.png'),
          redirect: '人文艺术',
          offer: '西蒙弗雷泽大学交互艺术与技术博士',
          biref: '研究领域：交互设计、体验设计、社会创新设计、思辨设计'
        },
        {
          name: 'Abner',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/abner.png'),
          redirect: '工学',
          offer: '帝国理工博士',
          biref: '研究领域：智能交通，交通时空异常预警'
        },
        {
          name: 'Thomas',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/Thomas1.png'),
          redirect: '社会科学',
          offer: '北京大学法学博士',
          biref: '研究领域：民商法； 公司法与证券法前沿、比较公司治理'
        },
        {
          name: 'Abby',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/abby.png'),
          redirect: '社会科学',
          offer: '北京大学文学博士',
          biref: '研究领域：文学、影视剧研究'
        },
        {
          name: 'Ashley',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/ashley.png'),
          redirect: '药学',
          offer: '南伊利诺伊大学生物医学博士',
          biref: '研究领域：肿瘤，代谢性疾病和衰老'
        },
        {
          name: 'Ronald',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/abby.png'),
          redirect: '工学',
          offer: '南洋理工大学',
          biref: '研究领域：土木工程方向'
        },
        {
          name: 'Gabire',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/gabire.png'),
          redirect: '社会科学',
          offer: '纽约州立大学宾汉姆顿分校翻译学博士',
          biref: '研究领域：翻译学、翻译理论、比较文学、文化研究、英语教育、语言学'
        },
        {
          name: 'Candice',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/Candice.png'),
          redirect: '社会科学',
          offer: '爱丁堡大学中文博士',
          biref: '研究领域：近代中国视觉文化，流行文化'
        },
        {
          name: 'Quentin',
          avatar: require('../../assets/img/home/Home/TeacherPower/swiper-imgs/quentin.png'),
          redirect: '社会科学',
          offer: '爱丁堡大学临床心理学博士',
          biref: '研究领域：常见心理疾病诊断及干预；脑部神经病变及区域认知能力紊乱'
        }
      ]
    }
  },
  components: {
    MyImage,
    Swiper,
    SwiperSlide
  },
  methods: {
    changeCricState (state) {
      // console.log(state);
      this.checkedIndex = state
    },
    openZoos () {
      openJesongChatByGroup(34320, 48064)
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
    #teacher-power{
        .teacher-power-title{
            .teacher-power-icon{
                width: 4.38rem;
                height: 4.69rem;
                background-image: url("../../assets/img/home/Home/TeacherPower/王牌导师.png");
            }
            .teacher-power-title-text{
                margin-left: 1.19rem;
                user-select: none;
                span{
                    font-size: 2.5rem;
                    font-weight: 600;
                    color: #8071F6;
                    line-height: 3.5rem;
                }
            }
        }
        .teacher-power-top-container{
            margin-top: 5.81rem;
            margin-bottom: 7rem;
            .top-center-container{
                width: 21.44rem;
                height: 21.44rem;
                .center-top-pane{
                    .pane-nav1{
                        width: 12.38rem;
                        height: 10.69rem;
                        cursor: pointer;
                    }
                    .pane-nav2{
                        width: 10.69rem;
                        height: 12.38rem;
                        cursor: pointer;
                    }
                }
                .center-text-box{
                    color: #4B4C5E;
                    transition: all .3s;
                    .title{
                        text-align: center;
                        span{
                            font-size: 1.38rem;
                            font-weight: 600;
                            line-height: 1.88rem;
                        }
                    }
                    .text{
                        text-align: center;
                        margin-top: 0.94rem;
                        span{
                            font-size: 1rem;
                            font-weight: 400;
                            line-height: 1.38rem;
                        }
                    }
                }
                .checked{
                    transform: scale(1.06);
                    .title{
                        color: #8071F6 !important;
                    }
                    .text{
                        color: #8071F6 !important;
                    }
                }
            }
            .teacher-doctor-swiper-container{
                width: 87.94rem;
                height: 26.44rem;
                margin-top: 7rem;
                .swiper-top{
                    width: 87.94rem;
                    height: 24.84rem;
                    .left-arrow{
                        width: 2.75rem;
                        height: 2.75rem;
                        background-image: url("../../assets/img/home/Home/TeacherPower/向左圆箭头 左箭头 向左 线性 (1).png")
                    }
                    .doctor-swiper{
                        width: 74.94rem;
                        height: 24.88rem;
                        // background-color: rgba(0,0,0,.05);
                        .doctor-swiper-nav:hover{
                            color: #4B4C5E !important;
                            opacity: 1 !important;
                            margin-left: 1.88rem;
                            box-shadow: 0rem 0rem 0.8rem 0.2rem rgba(0,0,0,.24) !important;
                            .service-btn{
                                background: #8071F6;
                                // box-shadow: 0rem 0.38rem 0.56rem 0rem rgba(106, 50, 139, 0.36);
                                span{
                                    font-size: 1.25rem;
                                    font-family: PingFangSC-Semibold, PingFang SC;
                                    font-weight: 600;
                                    color: #FFFFFF;
                                    line-height: 1.75rem;
                                }
                            }
                        }
                        .doctor-swiper-nav{
                            cursor: pointer;
                            transition: all .4s;
                            margin: 1rem 0;
                            margin-left: 1.88rem;
                            width: 23.75rem;
                            height: 24.84rem;
                            background: #FFFFFF;
                            border-radius: 0.25rem;
                            color: #878893;
                            box-shadow: 0rem 0rem 0.8rem 0.2rem rgba(0,0,0,.04);
                            z-index: 10;
                            // opacity: 0.62;
                            .doctor-swiper-nav-img{
                                width: 23.75rem;
                                height: 13.31rem;
                                .txt-span{
                                    margin-top: 4.13rem;
                                    span{
                                        font-size: 2.38rem;
                                        font-weight: 600;
                                        color: #FFFFFF;
                                        line-height: 3.31rem;
                                        letter-spacing: 0.1rem;
                                    }
                                }
                            }
                            .doctor-name{
                                text-align: left;
                                margin-left: 1.75rem;
                                margin-top: -1.44rem;
                                span{
                                    font-size: 1.38rem;
                                    font-weight: bold;
                                    line-height: 1.88rem;
                                }
                            }
                            .doctor-offer{
                                margin-top: 0.38rem;
                                margin-left: 1.75rem;
                                span{
                                    font-size: 0.88rem;
                                    font-weight: bold;
                                    line-height: 1.25rem;
                                }
                            }
                            .doctor-biref{
                                margin-top: 1.56rem;
                                margin-left: 1.75rem;
                                width: 19.69rem;
                                span{
                                    font-size: 0.88rem;
                                    font-weight: 400;
                                    line-height: 1.25rem;
                                }
                            }
                            .service-btn{
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                                bottom: 0.5rem;
                                margin-top: 1.44rem;
                                // margin-bottom: 1rem;
                                width: 9.31rem;
                                height: 2.63rem;
                                background: #C5C5C5;
                                border-radius: 1.31rem;
                                transition: all .4s;
                                span{
                                    font-size: 1.25rem;
                                    font-weight: 600;
                                    color: #FFFFFF;
                                    line-height: 1.75rem;
                                }
                            }
                        }
                    }
                    .right-arrow{
                        width: 2.75rem;
                        height: 2.75rem;
                        background-image: url("../../assets/img/home/Home/TeacherPower/向左圆箭头 左箭头 向左 线性.png")
                    }
                }
                .swiper-bottom{
                    .pagination-box{
                        margin-top: 3rem;
                        .doctor-pagination{
                            width: 19rem;
                            --swiper-pagination-color: rgba(0,0,0,.6)
                        }
                    }
                }
            }
        }
    }
    .anime{
        transition: all .4s;
        filter: grayscale(100%);
        // filter: drop-shadow(0rem 0rem 0rem block);
    }
    .anime2{
        transform: scale(1.1);
        filter: grayscale(0%) !important;
    }
</style>
