<template>
    <div id="product-system" class="default-size">
        <div style="margin-top: 7.06rem" class="product-system-title flex-row flex-center">
            <div class="product-system-icon bgImg-contain"></div>
            <div class="product-system-title-text">
                <span>产品体系</span>
            </div>
        </div>
        <div class="product-system-container bgImg-cover position-rel">
            <div class="product-system-content flex-colum flex-align-start position-abs">
                <div class="title">
                    <span>{{productInfos[productInfoIndex].title}}</span>
                </div>
                <div class="content">
                    <p>{{productInfos[productInfoIndex].content}}</p>
                </div>
                <div @click="openZoos" class="havrt-btn flex-colum flex-center btn-action cur-pointer">
                    <span>获取个人规划</span>
                </div>
            </div>
            <div class="container-selector position-abs flex-colum flex-align-center">
                <div @mouseenter="changeProductSystemState(1)" @click="toUrl(1)" :class="`option flex-row flex-center ${selectorState == 1 ? 'checked-option' : ''}`">
                    <span>博士留学</span><i class="icon bgImg-contain"></i>
                </div>
                <div class="line">
                    <div class="t"></div>
                </div>
                <div @mouseenter="changeProductSystemState(2)" @click="toUrl(2)" :class="`option flex-row flex-center ${selectorState == 2 ? 'checked-option' : ''}`">
                    <span>科研教育</span><i class="icon bgImg-contain"></i>
                </div>
                <div class="line">
                    <div class="t"></div>
                </div>
                <div @mouseenter="changeProductSystemState(3)" @click="toUrl(3)" :class="`option flex-row flex-center ${selectorState == 3 ? 'checked-option' : ''}`">
                    <span>学业辅导</span><i class="icon bgImg-contain"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      selectorState: 1,
      productInfoIndex: 0,
      productInfos: [
        {
          title: '曼博士 | 博士教育',
          content: '高端知识服务和人才提供商，全球博士孵化领航品牌，提供博士教育前、中、后全生命周期的赋能和服务。'
        },
        {
          title: '曼研 | 科研教育',
          content: '曼研为年龄在16+海内外学子无缝体验优质国际教育资源, 分阶段打造全方位学术背景和个人学术能力。通过科研让学员提升独立思考、逻辑思辨、团队合作、创新意识和学术软实力，助力学术成就未来！'
        },
        {
          title: '曼知 | 学业辅导',
          content: '针对留学生海外学习需求，曼知提供过渡课程辅导、课中辅导、论文提升、考前突击、论文润色&批改等服务。'
        }
      ]
    }
  },
  methods: {
    toUrl (index) {
      switch (index) {
        case 1:
          window.location = 'http://www.minhandedu.com/bssq/#/'
          break
        case 2:
          window.location = 'http://www.minhandedu.com/kyjy/'
          break
        case 3:
          window.location = 'http://www.minhandedu.com/xyfd/#/'
          break
      }
    },
    changeProductSystemState (state) {
      // if(this.actionFlag){
      //     this.actionFlag = false;
      this.selectorState = state
      const _slef = this
      var tl = this.$anime({
        targets: '.product-system-content',
        opacity: 0,
        translateX: -300,
        duration: 300,
        easing: 'linear',
        complete: function (anime) {
          _slef.productInfoIndex = state - 1
          _slef.$anime({
            targets: '.product-system-content',
            opacity: 1,
            translateX: 0,
            duration: 800,
            easing: 'easeInOutBack',
            complete: function () {
              _slef.actionFlag = true
            }
          })
        }
      })
      // }
    },
    openZoos () {
      openJesongChatByGroup(34320, 48064)
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
    #product-system{
        height:auto;
        .product-system-title{
            .product-system-icon{
                width: 4.38rem;
                height: 4.69rem;
                background-image: url("../../assets/img/home/Home/ProductSystem/服务体系.png");
            }
            .product-system-title-text{
                margin-left: 1.19rem;
                user-select: none;
                span{
                    font-size: 2.5rem;
                    font-weight: 600;
                    color: #8071F6;
                    line-height: 3.5rem;
                }
            }
        }
        .product-system-container{
            width: 100%;
            height: 43.13rem;
            margin-top: 3.44rem;
            border-top: 1px solid white;
            background-image: url("../../assets/img/home/Home/ProductSystem/bg.png");
            .product-system-content{
                width: 36.44rem;
                height: 25.94rem;
                top: 8.19rem;
                left: 22.63rem;
                .title{
                    span{
                        font-size: 2.13rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 3rem;
                    }
                }
                .content{
                    margin-top: 4.56rem;
                    p{
                        font-size: 1.63rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 2.38rem;
                    }
                }
                .havrt-btn{
                    width: 11.31rem;
                    min-height: 2.63rem;
                    background: #8071F6;
                    margin-top: 8.63rem;
                    user-select: none;
                    // box-shadow: 0rem 0.38rem 0.56rem 0rem rgba(106, 50, 139, 0.36);
                    border-radius: 1.88rem;
                    span{
                        font-size: 1.25rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 1.75rem;
                    }
                }

            }
            .container-selector{
                width: 33.94rem;
                height: 100%;
                border-radius: 0.06rem;
                right: 18.44rem;
                .line{
                    width: 100%;
                    height: 0.06rem;
                    background-color: rgba(0,0,0,.4);
                    .t{
                        width: 30.31rem;
                        height: 0.06rem;
                        margin: 0 auto;
                        background-color: #979797;
                    }
                }
                .option{
                    width: 100%;
                    height: 14.38rem;
                    border-radius: 0.06rem;
                    background-color: rgba(0,0,0,.4);
                    cursor: pointer;
                    transition: all .35s;
                    user-select: none;
                    span{
                        font-size: 1.5rem;
                        font-weight: 500;
                        color: #FFFFFF;
                        line-height: 2.06rem;
                        transition: all .35s;
                    }
                    .icon{
                        opacity: 0;
                        margin-left: 4rem;
                        width: 0.94rem;
                        height: 1.69rem;
                        transition: all .35s;
                        transform: translateX(-80px);
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAbCAYAAACjkdXHAAABuklEQVQ4T5WUv0tWYRiGrzuzNDSLAgMbEqSlliY3x4YmF4eGIjJ3N8f+EbNICEToxxAN0SA0hDSIgw4RpFCRoqAlWpZ3PPJ8ouX3vcd3OefAua7znud97ke27wJngUeSVjjCku2RhL8Do5K+VeUD7gQGgdPABjAm6XMVgeIl2+eAe7mDrfyFTyXBLpyCDmAIOA/8AsYlfWgk2INT0JY7uAD8Bp5ImqsnOACnoDVrcBH4A0xKmjlM8B+cgpPAHaA7SgI8kzT9r+BQOAXNwC3gcgpeSnq7X1AXTkETcBO4mtBrSW9qgoZwCo4BA8C1hKYkvYr7IpyCeK8f6E3BO+BFJXhfL9wA+vJ5+qjwcWC41kiVYdsngNtAT1a/2rZtt+S5XwJ2gKeS3he/bPtUdlxXdtyEpNlitW23Z69HbLez1+eL52z7TKYs4voTeCzpY7HDbEcsI98h2Mx8LxR723bEMSZLbDkmywNJX4qpsh0xjIEYRVrPmbZUzLPtiF/EMOK4mmBc667aDIvYRfwihssJrjUCd4/K9pWMXbTe1/zHHyWwBt8HYvQsAg8lRXUrrfjy9TyS55JialZefwG7BZkd/zTrQQAAAABJRU5ErkJggg==);
                    }
                }
                .option:hover{
                    background-color: rgba(128, 113, 246,.4);
                    span{
                        transform: scale(1.5) !important;
                    }
                    .icon{
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
                .checked-option{
                    background-color: rgba(128, 113, 246,.4);
                    span{
                        transform: scale(1.5) !important;
                    }
                    .icon{
                        opacity: 1;
                        transform: translateX(0);
                    }
                }
            }
        }
    }
</style>
