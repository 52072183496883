<template>
  <div class="home">
    <my-header />

    <banner />
    <education/>
    <product-system/>
    <teacher-power/>
    <student-share/>
    <service/>
    <news/>
    <!-- <cooperation/> -->

    <my-footer style="margin-top:5.44rem"/>
    <div class="float-muen flex-colum flex-align-center flex-jusity-space-between">
      <div v-show="toTopShow" @click="toTop" class="toTop flex-colum flex-align-center">
        <div class="icon bgImg-cover"></div>
        <div class="fonts">
          <span>回到顶部</span>
        </div>
      </div>
      <div @click="openZoos" class="consulting flex-colum flex-align-center">
        <div class="icon bgImg-cover"></div>
        <div class="fonts">
          <span>小曼博士</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyHeader from '../components/MyHeader'
import Banner from '../components/Home/Banner'
import MyFooter from '../components/MyFooter'
import Education from '../components/Home/Education'
import ProductSystem from '../components/Home/ProductSystem'
import TeacherPower from '../components/Home/TeacherPower'
import Service from '../components/Home/Service'
import News from '../components/Home/News'
import Cooperation from '../components/Home/Cooperation'
import StudentShare from '..//components/Home/StudentShare'

import { scrollTo, scrollIntoView } from 'scroll-js'

import util from '../utils/util'
export default {
  data () {
    return {
      toTopShow: false
    }
  },
  components: {
    MyHeader,
    Banner,
    MyFooter,
    Education,
    ProductSystem,
    TeacherPower,
    Service,
    News,
    Cooperation,
    StudentShare
  },
  mounted () {
    const _slef = this
    var scrollFun = util.debounce(function () {
      const $scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
      if (
        $scrollTop >= 300
      ) {
        _slef.toTopShow = true
        return
      }
      if ($scrollTop == 0) {
        _slef.toTopShow = false
      }
    }, 200)
    window.addEventListener('scroll', scrollFun, true)
  },
  methods: {
    toTop () {
      scrollIntoView(document.getElementsByClassName('home')[0], document.body, { behavior: 'smooth' }).then(() => {
        this.toTopShow = false
      })
    },
    openZoos () {
      openJesongChatByGroup(34320, 48064)
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.home{
  overflow: hidden;
}
  .float-muen{
    position: fixed;
    width: 4.63rem;
    height: 10.5rem;
    bottom: 8rem;
    right: 1rem;
    z-index: 10;
    .toTop{
      cursor: pointer;
      width: 4.63rem;
      height: 4.63rem;
      background-color: rgba(89,89,89, .83);
      border-radius: 0.4rem;
      .icon{
        margin-top: 1rem;
        width: 1.63rem;
        height: 1.75rem;
        background-image: url("../assets/img/home/回到顶部@2x.png");
      }
      .fonts{
        span{
          font-size: 0.75rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1.06rem;
        }
      }
    }
    .consulting{
      cursor: pointer;
      width: 4.63rem;
      height: 4.63rem;
      // background-color: rgba(106,50,139, 1);
      background-color: #8071F6;
      border-radius: 0.4rem;
      .icon{
        margin-top: 1rem;
        width: 1.88rem;
        height: 1.88rem;
        background-image: url("../assets/img/home/咨询 @2x.png");
      }
      .fonts{
        span{
          font-size: 0.75rem;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1.06rem;
        }
      }
    }
  }
</style>
